import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import { Button } from "@mui/material";
import TabTyreInventoryView from "../../../Components/TabTyreInventory/TabTyreInventory/index.js";
import TabTyreDetails from "../../../Components/TabFleet/TabTyreDetails/index.js";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { isMobile, isTablet } from "react-device-detect";
import { Box } from "@mui/system";
import { ArrowBackOutlined } from "@mui/icons-material";
import Images from "../../../Config/Images.js";
import AddInventoryTyre from "../../../Components/TyreInventory/AddInventory/index.js";
import RemoveTyre from "../../../Components/TyreHistory/RemoveTyre/index.js";
import { color } from "../../../Config/theme.js";
import { useLocation } from "react-router-dom";

export default function TabTyreInventory() {
  const isKeyboardOpen = useDetectKeyboardOpen();

  const styles = useStyles();
  const location = useLocation();
  const [changeTab, setChangeTab] = useState("");
  const [editMasterData, setEditMasterData] = useState({});
  const [from, setFrom] = useState("");
  const [datePicker, setDatePicker] = useState(null);
  const [storedCategory, setStoredCategory] = useState("");

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);
  const [filter, setFilter] = useState("");
  const [selectFleet, setSelectFleet] = useState("");
  const [search, setSearch] = useState("");
  const [tsn, setTsn] = useState(location?.state?.tyre_serial_no);

  useEffect(() => {
    document.title = Setting.page_name.TyreInventory;
  }, []);

  return (
    <div className={styles.card}>
      {isTablet
        ? null
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}
      {(changeTab === "tyreDetails" ||
        changeTab === "addTyre" ||
        changeTab === "removeTyre") && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              variant="contained"
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                if (changeTab === "removeTyre") {
                  setChangeTab("addTyre");
                  setFrom("inside#cancel");
                } else {
                  setChangeTab("");
                  setTsn("");
                  // setPageNoIL(1);
                }
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "tyreDetails" ? (
        <TabTyreDetails tyreData={editMasterData} from={"tyreInventory"} />
      ) : changeTab === "addTyre" ? (
        <AddInventoryTyre
          storedCategory={storedCategory}
          fromInsideType={from}
          editData={editMasterData}
          handleClick={(v, data) => {
            if (v === "cancel") {
              setTsn("");
              setChangeTab("");
              // setPageNoIL(1);
            } else if (v === "removeTyre") {
              setStoredCategory(data);
              setChangeTab("removeTyre");
            }
          }}
        />
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          from={"tyreInventory"}
          tyreData={editMasterData}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setFrom("inside#cancel");
              setChangeTab("addTyre");
            } else if (type === "success") {
              setFrom("inside#success");
              setChangeTab("addTyre");
            }
          }}
        />
      ) : (
        <TabTyreInventoryView
          tsn={tsn}
          pageNo={pageNoIL}
          record={recordIL}
          filter={filter}
          selectFleet={selectFleet}
          search={search}
          date={datePicker}
          handlePageNo={(page) => {
            setPageNoIL(page);
          }}
          handleRecord={(rec) => {
            setRecordIL(rec);
          }}
          handleFilter={(flt) => {
            setFilter(flt);
          }}
          handleSelectFleet={(flt) => {
            setSelectFleet(flt);
          }}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handledate={(date) => {
            setDatePicker(date);
          }}
          handleClick={(v, data) => {
            if (v === "tyreDetails") {
              setChangeTab("tyreDetails");
              setEditMasterData(data);
              setFrom("tyreIvemtory");
            } else if (v === "addTyre") {
              setChangeTab("addTyre");
              setEditMasterData(data);
            }
          }}
        />
      )}
    </div>
  );
}
