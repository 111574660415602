import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  Tab,
  Tabs,
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Pagination,
  CircularProgress,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import { color } from "../../../Config/theme.js";
import { FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

export default function VehicleAttention(props) {
  const { handleClick = () => null } = props;
  const styles = useStyles();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");
  const [selectFleet, setSelectFleet] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [fleetList, setFleetList] = useState(false);

  const [pageNoV, setPageNoV] = useState(1);
  const [recordV, setRecordV] = useState(10);
  const [searchFilterV, setSearchFilterV] = useState("");
  const [filterV, setFilterV] = useState("");
  const [filterIdV, setFilterIdV] = useState("");

  const [pageNoM, setPageNoM] = useState(1);
  const [recordM, setRecordM] = useState(10);
  const [searchFilterM, setSearchFilterM] = useState("");
  const [filterM, setFilterM] = useState("");
  const [filterIdM, setFilterIdM] = useState("");

  const [pageLoad, setPageLoad] = useState(true);
  const [pageLoadV, setPageLoadV] = useState(true);
  const [pageLoadM, setPageLoadM] = useState(true);

  const [inspectionListRows, setinspectionListRows] = useState({});

  const [pendingReadingListRows, setpendingReadingListRows] = useState({});
  const [pendingMileageListRows, setpendingMileageListRows] = useState({});

  const [reportLoader, setReportLoader] = useState(false);
  const { token, dropDownData, isOnline, userdata, fleetData } = useSelector(
    (state) => state.auth
  );

  const inspectionColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
    },
    {
      field: "created_at",
      headerName: "Last Inspected On",
      width: 220,
      renderCell: (params) => {
        const timestamp = params?.row?.last_inspected_at;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.last_inspected_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "unresolved_tyre_issue",
      headerName: "Unresolved Tyre Issues",
      width: 230,
      renderCell: (params) => {
        const data = params?.row?.unresolved_tyre_issue;
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isEmpty(data) ? (
              Object.keys(data).map((item) => {
                return (
                  <div>
                    <Typography>
                      {item} : {data[item]}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <Typography>-</Typography>
            )}
          </div>
        );
      },
    },
    {
      field: "unresolved_mechanical_issue",
      headerName: "Unresolved Mechanical Issues",
      width: 280,
      renderCell: (params) => {
        const data = params?.row?.unresolved_mechanical_issue;
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isEmpty(data) ? (
              Object.keys(data).map((item) => {
                return (
                  <div>
                    <Typography>
                      {item} : {data[item]}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <Typography>-</Typography>
            )}
          </div>
        );
      },
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 220,
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "vehicle_type_id",
      headerName: "Vehicle Type",
      width: 220,
      renderCell: (params) => {
        let array = params?.row?.vehicle_type_id;
        let res =
          !isNull(array) &&
          dropDownData?.vehicle_type?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_brand_id",
      headerName: "Vehicle Brand",
      width: 220,
      renderCell: (params) => {
        let array = params?.row?.vehicle_brand_id;
        let res =
          !isNull(array) &&
          dropDownData?.vehicle_brand?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "driver_name",
      headerName: "Driver's Name",
      width: 220,
      renderCell: (params) => {
        return params?.row?.driver_name || "-";
      },
    },

    {
      field: "reason",
      headerName: "Reason",
      width: 220,
      sortable: true,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: params?.row?.reason === "Overdue Inspection" && "red",
            }}
          >
            {params?.row?.reason}
          </Typography>
        );
      },
    },
  ];

  const pendingReadingColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoV === 1 ? index : (pageNoV - 1) * recordV + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
      // flex: !lg ? 1 : "unset",
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 220,
      // flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 220,
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 220,
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "driver_name",
      headerName: "Driver's Name",
      width: 220,
      renderCell: (params) => {
        return params?.row?.driver_name || "-";
      },
    },
    {
      field: "inspection_date",
      headerName: "Last Inspected On",
      width: 220,
      // flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.inspection_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.inspection_date ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  const pendingMileageColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoM === 1 ? index : (pageNoM - 1) * recordM + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 220,
      renderCell: (params) => {
        return <Typography>{params?.row?.tyre_serial_no}</Typography>;
      },
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 220,
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 220,
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "tyre_condition",
      headerName: "Current Condition",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 220,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 140,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Discription",
      width: 220,
      renderCell: (params) => {
        return params?.row?.tyre_pattern_size || "-";
      },
    },
    {
      field: "tyre_position",
      headerName: "Current Position",
      width: 220,
    },
    {
      field: "axle_type",
      headerName: "Current Axle Type",
      width: 220,
    },
  ];

  const DataGridRender = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        getRowHeight={() => "auto"}
        rows={inspectionListRows?.data}
        columns={inspectionColumns}
        // onCellClick={(params) => {
        //   navigate("/dashboard/FleetManagement", {
        //     state: {
        //       set: "dashboardDetails",
        //       fleet_id: params?.row?.fleet_id,
        //       fleet_name: params?.row?.fleet_name,
        //       vehicle_no: params?.row?.vehicle_no,
        //       id: params?.row?.vehicle_id,
        //       no_of_tyres: params?.row?.no_of_tyres,
        //       vehicle_brand_id: params?.row?.vehicle_brand_id,
        //       vehicle_type_id: params?.row?.vehicle_type_id,
        //       driver_name: params?.row?.driver_name,
        //       driver_phone: params?.row?.driver_phone,
        //       no_of_rows: params?.row?.no_of_rows,
        //     },
        //   });
        // }}
        onCellClick={(params) => {
          navigate("/dashboard/Inspection", {
            state: { set: "addInspection", data: params?.row },
          });
        }}
        pageSize={record}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
      />
    );
  }, [inspectionListRows]);

  const DataGridRender1 = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={pendingReadingListRows?.data}
        columns={pendingReadingColumns}
        pageSize={recordV}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        showColumnRightBorder
        disableSelectionOnClick
        onCellClick={(params) => {
          navigate("/dashboard/Inspection", {
            state: {
              set: "dashboardDetails",
              fleet_id: params?.row?.fleet_id,
              fleet_name: params?.row?.fleet_name,
              vehicle_id: params?.row?.vehicle_id,
              date: params?.row?.inspection_date,
              vehicle_no: params?.row?.vehicle_no,
            },
          });
        }}
      />
    );
  }, [pendingReadingListRows]);

  const DataGridRender2 = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={pendingMileageListRows?.data}
        columns={pendingMileageColumns}
        pageSize={recordM}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        onCellClick={(params) => {
          navigate("/dashboard/TyreInventory", {
            state: {
              set: "dashboardDetails",
              tyre_serial_no: params?.row?.tyre_serial_no,
            },
          });
        }}
        showColumnRightBorder
        disableSelectionOnClick
      />
    );
  }, [pendingMileageListRows]);

  // intialRender used for stop multiple time apiCall
  const initialRender = useRef(true);
  const initialRenderReading = useRef(true);
  const initialRenderMileage = useRef(true);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Dashboard`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  useEffect(() => {
    setPageNo(1);
    setRecord(10);
    setPageNoV(1);
    setRecordV(10);
    setPageNoM(1);
    setRecordM(10);
    setFilter("");
    setSelectFleet("");
    setFilterM("");
    setFilterV("");
    setFilterIdM("");
    setFilterIdV("");
  }, [tabValue]);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      if (token !== "") {
        getinspectionList(false);
      }
    }
  }, [record, pageNo, filter, selectFleet, token]);

  useEffect(() => {
    setPageNo(1);
  }, [record]);

  useEffect(() => {
    setPageNoV(1);
  }, [recordV]);

  useEffect(() => {
    setPageNoM(1);
  }, [recordM]);

  useEffect(() => {
    if (initialRenderReading?.current) {
      initialRenderReading.current = false;
    } else {
      if (token !== "") {
        getpendingRList(false);
      }
    }
  }, [recordV, pageNoV, token, filterV]);

  useEffect(() => {
    if (initialRenderMileage?.current) {
      initialRenderMileage.current = false;
    } else {
      if (token !== "") {
        getpendingMList(false);
      }
    }
  }, [recordM, pageNoM, token, filterM]);

  useEffect(() => {
    if (token !== "") {
      isEmpty(searchFilter) && getinspectionList(false);
    }
  }, [searchFilter]);

  useEffect(() => {
    if (token !== "") {
      isEmpty(searchFilterV) && getpendingRList(false);
    }
  }, [searchFilterV]);

  useEffect(() => {
    if (token !== "") {
      isEmpty(searchFilterM) && getpendingMList(false);
    }
  }, [searchFilterM]);

  function resetFilterData() {
    setFilter("");
    setSelectFleet("");
    setSearchFilter("");
    setPageNo(1);
  }

  function resetMFilterData() {
    setSearchFilterM("");
    setPageNoM(1);
    setFilterM("");
    setFilterIdM("");
  }

  function resetVehicleData() {
    setSearchFilterV("");
    setPageNoV(1);
    setFilterV("");
    setFilterIdV("");
  }

  async function getinspectionList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.attentionvehicleList
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&filter=${filter || ""}&is_download=${v || ""}&fleet_id=${
          selectFleet.replaceAll("&", "%26") || ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadInspectionData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setinspectionListRows(listObj);
          }
        v && auditSave("Inspection List");
      } else {
        setinspectionListRows({});
      }
      setReportLoader(false);
      setPageLoad(false);
    } catch (error) {
      setReportLoader(false);
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getpendingRList(bool, v) {
    !v && setPageLoadV(true);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.pendingReadingList
        }?per_page=${recordV}&page=${pageNoV}&search=${
          bool ? "" : searchFilterV
        }&is_download=${v || ""}&filter=${filterIdV}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadReadingData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setpendingReadingListRows(listObj);
          }
        v && auditSave("Pending Reading List");
      } else {
        setpendingReadingListRows({});
      }
      setPageLoadV(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoadV(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getpendingMList(bool, v) {
    !v && setPageLoadM(true);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.pendingMileageList
        }?per_page=${recordM}&page=${pageNoM}&search=${
          bool ? "" : searchFilterM
        }&is_download=${v || ""}&filter=${filterIdM}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadMilageData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setpendingMileageListRows(listObj);
          }
        v && auditSave("Pending Mileage List");
      } else {
        setpendingMileageListRows({});
      }
      setPageLoadM(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoadM(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadInspectionData(data) {
    const newData = data.map((item, index) => {
      let vehicleType =
        !isNull(item?.vehicle_type_id) &&
        dropDownData?.vehicle_type?.find(
          (v) => item?.vehicle_type_id?.toString() === v?.id?.toString()
        );

      let vehicleBrand =
        !isNull(item?.vehicle_brand_id) &&
        dropDownData?.vehicle_brand?.find(
          (v) => item?.vehicle_brand_id?.toString() === v?.id?.toString()
        );

      const timestamp = item?.last_inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      let issueObj = item?.unresolved_tyre_issue;
      let arr = [];
      let obj = Object.keys(issueObj).map((item) => {
        arr.push(`${item} : ${issueObj[item]}`);
      });

      let issueObj1 = item?.unresolved_mechanical_issue;
      let arr1 = [];
      let obj1 = Object.keys(issueObj1).map((item) => {
        arr1.push(`${item} : ${issueObj1[item]}`);
      });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Last Inspected On": item?.last_inspected_at ? displayDate : "-",
        "Unresolved Tyre Issues": arr.toString() || "-",
        "Unresolved Mechanical Issues": arr1.toString() || "-",
        "No. of Tyres": `${item?.added_tyre_count}/${item?.no_of_tyres}`,
        "Vehicle Type": vehicleType?.label || "-",
        "Vehicle Brand": vehicleBrand?.label || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Driver's Name": item?.driver_name || "-",
        Reason: item?.reason || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inspection List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection List.xlsx");
  }

  function downloadReadingData(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.last_inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "No. of Tyres": `${item?.added_tyre_count}/${item?.no_of_tyres}`,
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Driver's Name": item?.driver_name || "-",
        "Last Inspected On": item?.last_inspected_at ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Pending Reading List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Pending Reading List.xlsx");
  }

  function downloadMilageData(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Tyre Serial No.": item?.tyre_serial_no,
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Current Condition": item?.tyre_condition || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Discription": item?.tyre_pattern_size,
        "Current Position": item?.tyre_position || "-",
        "Current Axle Type": item?.axle_type || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Pending Mileage List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Pending Mileage List.xlsx");
  }

  return (
    <div className={styles.fleetMain}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        height={40}
      >
        <Typography variant="tableTitle">
          Vehicles That Need Attention
        </Typography>
        <Button
          disabled={isOnline ? false : true || reportLoader}
          style={{
            backgroundColor: isOnline ? color.secondary : color.bordercolor,
          }}
          onClick={() => {
            tabValue === 0
              ? getinspectionList(false, 1)
              : tabValue === 1
              ? getpendingRList(false, 1)
              : getpendingMList(false, 1);
          }}
        >
          {reportLoader ? (
            <CircularProgress style={{ color: color.white }} size={24} />
          ) : (
            "Download"
          )}
        </Button>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
        <Tabs
          value={tabValue}
          onChange={(v, b) => {
            setTabValue(b);
          }}
          variant="scrollable"
        >
          <Tab className={styles.tabBtn} label="Inspection" />
          <Tab className={styles.tabBtn} label="Pending Reading" />
          <Tab className={styles.tabBtn} label="Pending Mileage" />
        </Tabs>
      </Box>
      <div style={{ marginTop: 14 }}>
        {tabValue === 0 ? (
          <div>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10.4}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item lg={1} style={{ marginRight: 20 }}>
                  <Select
                    fullWidth
                    value={selectFleet}
                    onChange={(v) => {
                      setPageNo(1);
                      setSelectFleet(v.target.value);
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={selectFleet === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Fleet
                    </MenuItem>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: "flex" }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item lg={1}>
                  <Select
                    fullWidth
                    value={filter}
                    onChange={(v) => {
                      setPageNo(1);
                      setFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        settypeList(false);
                        setbrandList(false);
                        setFleetList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        settypeList(!typeList);
                        setFleetList(false);
                        setbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData?.vehicle_type?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: typeList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        settypeList(false);
                        setFleetList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData?.vehicle_brand?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item lg={4} style={{ marginLeft: 20 }}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getinspectionList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter)) {
                          setPageNo(1);
                          getinspectionList(false);
                        }
                      }
                    }}
                  />
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter)) {
                        setPageNo(1);
                        getinspectionList(false);
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={resetFilterData}
                    disabled={
                      filter === "" &&
                      isEmpty(selectFleet) &&
                      isEmpty(searchFilter)
                    }
                  >
                    Reset
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    setPageNo(1);
                    setRecord(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(inspectionListRows?.data) &&
              !isEmpty(inspectionListRows?.data) ? (
              <>
                <div style={{ marginTop: 20 }}>{DataGridRender}</div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(inspectionListRows?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
        {tabValue === 1 ? (
          <div>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10.4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={1.6} style={{ marginRight: 20 }}>
                  <Select
                    fullWidth
                    value={filterV}
                    onChange={(v) => {
                      setPageNoV(1);
                      setFilterV(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filterV.toString())) {
                        setFleetList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filterV === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setFleetList(!fleetList);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Fleet</Typography>
                      {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {fleetData.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: fleetList ? "flex" : "none" }}
                          key={index}
                          value={item?.label}
                          onClick={() => {
                            setFilterIdV(item?.id);
                          }}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilterV}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getpendingRList(true);
                      setSearchFilterV(v?.target?.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilterV)) {
                          setPageNoV(1);
                          getpendingRList(false);
                        }
                      }
                    }}
                  />
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilterV)) {
                        setPageNoV(1);
                        getpendingRList(false);
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={resetVehicleData}
                    disabled={
                      filterV === "" && isEmpty(searchFilterV) ? true : false
                    }
                  >
                    Reset
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={recordV}
                  onChange={(v) => {
                    setPageNoV(1);
                    setRecordV(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {pageLoadV ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(pendingReadingListRows?.data) &&
              !isEmpty(pendingReadingListRows?.data) ? (
              <>
                <div style={{ marginTop: 20 }}>{DataGridRender1}</div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      pendingReadingListRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNoV}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNoV(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
        {tabValue === 2 ? (
          <div>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10.4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={1.6} style={{ marginRight: 20 }}>
                  <Select
                    fullWidth
                    value={filterM}
                    onChange={(v) => {
                      setPageNoM(1);
                      setFilterM(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filterM.toString())) {
                        setFleetList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filterM === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setFleetList(!fleetList);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Fleet</Typography>
                      {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {fleetData.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: fleetList ? "flex" : "none" }}
                          key={index}
                          value={item?.label}
                          onClick={() => {
                            setFilterIdM(item?.id);
                          }}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilterM}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getpendingRList(true);
                      setSearchFilterM(v?.target?.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilterM)) {
                          setPageNoM(1);
                          getpendingMList(false);
                        }
                      }
                    }}
                  />
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilterM)) {
                        setPageNoM(1);
                        getpendingMList(false);
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={resetMFilterData}
                    disabled={
                      filterM === "" && isEmpty(searchFilterM) ? true : false
                    }
                  >
                    Reset
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={recordM}
                  onChange={(v) => {
                    setPageNoM(1);
                    setRecordM(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {pageLoadM ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(pendingMileageListRows?.data) &&
              !isEmpty(pendingMileageListRows?.data) ? (
              <>
                <div style={{ marginTop: 20 }}>{DataGridRender2}</div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      pendingMileageListRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNoM}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNoM(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
