const prod = process.env.NODE_ENV === "production";
const baseUrl = prod
  ? "https://api.fkadm.in/v1/"
  : "http://192.168.1.62/fleet-api/v1/";
export const Setting = {
  baseUrl,
  api: baseUrl,
  isTablet: window.innerWidth <= 768,
  isMobile: window.innerWidth <= 425,

  endpoints: {
    login: "user/login",
    logout: "user/logout",
    sendotp: "user/send-otp",
    verifyotp: "user/verify-otp",
    resetpassword: "user/reset-password",
    resendOtp: "user/resend-otp",
    changePassword: "user/change-password",
    dropDown: "system/dropdown",
    addDropDown: "system/add-dropdown",
    deleteDropDown: "system/delete-dropdown",
    FleetData: "system/fleet-data",
    serviceEngineerList: "system/service-enginner-list",
    uploadImg: "fleet/upload-file",
    multiUploadImg: "fleet/upload-multiple",
    vehicleData: "system/vehicle-data",
    getMileage: "system/get-mileage-data",
    systemcommon: "system/common",
    systemdashboard: "system/dashboard",
    rograph: "system/ro-graph",

    vehicleList: "vehicle/list-vehicle",
    vehicledetails: "vehicle/vehicle-details",
    importvehicle: "system/import-vehicle",
    attentionvehicleList: "vehicle/attention-vehicle-list",
    pendingMileageList: "vehicle/pending-mileage-list",
    pendingReadingList: "vehicle/pending-reading-vehicle-list",
    changeVehicleStatus: "vehicle/change-vehicle-status",

    addfleet: "fleet/add-fleet",
    fleetList: "fleet/list-fleet",
    deletefleet: "fleet/delete-fleet",
    addfleetuser: "fleet/fleet-user-assignment",
    fleetdetails: "fleet/fleet-details",
    deleteIssue: "fleet/delete-issue",

    tyreList: "vehicle/tyre-list",
    tyreposition: "vehicle/tyre-positions",
    switchTyre: "vehicle/switch-tyre",
    importtyre: "system/import-tyre",
    tyreHealth: "vehicle/tyre-health-visualization",
    issueCount: "vehicle/get-tyre-issue-count",

    userlist: "user/list-users",
    adduser: "user/add-user",
    allocatedlist: "user/list-allocated-users",
    changefleetallocated: "user/change-fleet-allocated",
    deleteuser: "user/delete-user",
    permissionList: "system/permission-list",
    savepermission: "system/save-permission",

    addvehicle: "vehicle/add-vehicle",
    deletevehicle: "vehicle/delete-vehicle",
    tyreserialno: "vehicle/tyre-serialno-list",
    addtyre: "vehicle/add-tyre",
    removeTyre: "vehicle/remove-tyre",
    patterndata: "system/get-data-by-pattern",
    tyrepattern: "system/tyre-pattern-data",
    addreading: "vehicle/add-reading",

    inspectionlist: "fleet/list-inspection",
    inspectionhistorylist: "fleet/list-vehicle-inspection",
    resolvedissue: "fleet/resolution-list",
    inspectiontyrelist: "fleet/list-tyre-inspection",
    savereading: "fleet/save-reading",
    unresolveissue: "fleet/unresolved-tyre-list",
    issueList: "fleet/issue-list",
    resolveissue: "fleet/resolve-issue",
    addinspectiontyrelist: "vehicle/tyre-list-inspection",
    addinspection: "fleet/add-inspection",
    updateinspection: "fleet/update-inspection",
    resolutionDetail: "fleet/resolution-detail",
    deleteInspection: "fleet/delete-inspection",

    modificationlist: "vehicle/modification-list",
    modificationrequest: "vehicle/modification-request",
    approvemodificationrequest: "vehicle/approve-modification-request",
    updaterequest: "vehicle/update-request",

    scraplist: "fleet/list-scrap",
    addscraptyre: "fleet/add-scrap-tyre",
    scrapdetails: "fleet/scrap-details",
    uploadCSV: "system/import-scrap-tyre",

    importmaster: "system/item-master-import",
    itemmasterList: "system/item-master-list",
    additemmaster: "system/add-item",
    deleteitem: "system/delete-item",
    pendingrequest: "system/pending-new-tyre-request",
    vehiclemasterlist: "system/vehicle-master-list",
    vehiclemasterdata: "system/vehicle-master-data",
    vehiclemodeldata: "system/vehicle-model-data",
    checkhorsetrailer: "system/check-horse-trailer",
    addvehiclemaster: "system/add-vehicle-master",
    getdatabyvehicle: "system/get-data-by-vehicle-model",
    gethorsevehiclelist: "system/list-horse-trailer-vehicles",

    lifehistorylist: "vehicle/life-history",
    removelifehistory: "vehicle/remove-life-history",
    tyreHistoryList: "vehicle/switch-tyre-list",
    tyreHistoryDetails: "vehicle/switch-tyre-history",
    conditionHistory: "vehicle/condition-history",
    conditionList: "vehicle/condition-list",
    tyreDetails: "vehicle/tyre-details",
    tyremileagehistory: "vehicle/tyre-mileage-history",

    servicecounter: "vehicle/service-dashboard",
    importservicecenter: "system/import-service-center",
    verifycaller: "vehicle/verify-caller",
    ticketlist: "vehicle/ticket-list",
    outstandingpaymentlist: "vehicle/outstanding-payments",
    servicecenterlist: "vehicle/service-center-list",
    createticket: "vehicle/create-ticket",
    ticketdetails: "vehicle/ticket-details",

    notificationlist: "notification/list",
    scrapIssueReport: "report/scrap-tyre-issue-graph",
    fleetissueGraph: "report/fleet-issue-graph",
    fleethealthGraph: "report/fleet-health-graph",
    fleetPie: "report/fleet-pie-chart",

    pendingIssueReport: "report/pending-issue-list",
    resolvedissuereport: "report/resolved-issue-report",
    avgnsdreport: "report/avg-nsd-report",
    inspectionreport: "report/inspection-report",
    scraptyreissuegraph: "report/scrap-tyre-issue-graph",
    ticketMasterReport: "report/ticket-master-list",
    tyreperformance: "report/tyre-performance",
    fleetTyreListReport: "report/fleet-tyre-list",
    cpkReport: "report/cpk-analysis",
    issueAnalysis: "report/issue-analysis",
    tyreMileageReport: "report/tyre-mileage-report",
    tyreInspectionReport: "report/tyre-inspection-report",
    kmReport: "report/km-report",

    issueanalysis: "report/issue-analysis",
    inspectionmasterreport: "report/inspection-master-report",
    updateDate: "vehicle/update-request-date",

    scrapnsdreport: "report/scrap-nsd-report",
    saveinspection: "fleet/save-inspection",

    itemInventoryList: "vehicle/tyre-inventory-list",
    tyreremovalrequest: "vehicle/send-removal-request",
    tyreremovalrequestlist: "vehicle/removal-request-list",
    removalrequestupdate: "vehicle/removal-request-update",
    deletetyre: "vehicle/delete-tyre",
    reportinventory: "report/inventory-report",
    tyrecategoryhistory: "vehicle/tyre-category-history",

    auditList: "user/audit-log",
    auditDetails: "user/audit-log-details",
    auditsave: "user/save-audit-log",

    getTSNdata: "system/get-tsn-data",
    getTSNinfo: "system/get-tsn-info",

    listtrailer: "vehicle/list-trailer",
    trailerlist: "vehicle/trailer-list",
    addtrailer: "vehicle/add-trailer",
    checktrailer: "vehicle/check-trailer-ideal",
    changeTrailer: "vehicle/change-trailer",
  },

  JS_Regex: {
    email_Regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    pwd1_Regex: /^.{8,}$/, // /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    phone_Regex: /^(\+44\s?\d{10}|0044\s?\d{10}|0\s?\d{10})?$/,
    alphabatic_Regex: /^[a-zA-Z\s]*$/,
    tyrePosition: /^(D|C)[0-9]{2}(I|M|O)$/,
    tyrePositionX: /^(D|C)[0-9]{2}(I|O)$/,
    currency_Regex: /^(?:0|[1-9]\d*)(?:\.(?!.*000)\d+)?$/,
    vehicleNo: /^[A-Z]{2}\s[0-9]{2}\s([A-Z]|[A-Z]{2}|[A-Z]{3})\s[0-9]{4}$/,
    trailerReg: /^([A-Z]{1,2})[0-9]{4}$/,
  },

  page_name: {
    OFFLINE: "Offline",
    Login: "Login",
    ChangePass: "Change Password",
    Notification: "Notifications",

    Dashboard: "Dashboard",
    FleetManagement: "Fleet Management",
    Inspection: "Inspection Management",
    ScrapAnalysis: "Scrap Analysis",
    ServiceCentre: "Service Centre",
    TyreHistory: "Tyre History",
    UserManagement: "User Management",
    ItemMaster: "Item Master",
    GeneralDropdowns: "General Dropdowns",
    TyreInventory: "Tyre Inventory",
    AuditLog: "Audit Log",
    VehicleMaster: "Vehicle Master",
    TrailerMaster: "Trailer Master",
  },
};
