import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Pagination,
  CircularProgress,
  ListItemButton,
  Tooltip,
  Popover,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import { color } from "../../../Config/theme.js";
import {
  FilterAlt,
  MoreVert,
  Search,
  TuneOutlined,
  Visibility,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { isTablet } from "react-device-detect";
import Images from "../../../Config/Images.js";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";

export default function TabVehicleAttention(props) {
  const styles = useStyles();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");
  const [selectFleet, setSelectFleet] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);

  const [pageNoV, setPageNoV] = useState(1);
  const [recordV, setRecordV] = useState(10);
  const [searchFilterV, setSearchFilterV] = useState("");
  const [filterV, setFilterV] = useState("");
  const [filterIdV, setFilterIdV] = useState("");

  const [pageNoM, setPageNoM] = useState(1);
  const [recordM, setRecordM] = useState(10);
  const [searchFilterM, setSearchFilterM] = useState("");
  const [filterM, setFilterM] = useState("");
  const [filterIdM, setFilterIdM] = useState("");

  const [pageLoad, setPageLoad] = useState(true);
  const [pageLoadV, setPageLoadV] = useState(true);
  const [pageLoadM, setPageLoadM] = useState(true);

  const [inspectionListRows, setinspectionListRows] = useState({});
  const [pendingReadingListRows, setpendingReadingListRows] = useState({});
  const [pendingMileageListRows, setpendingMileageListRows] = useState({});
  const [reportLoader, setReportLoader] = useState(false);

  const { token, dropDownData, isOnline, userdata, fleetData } = useSelector(
    (state) => state.auth
  );

  const [fleetList, setFleetList] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;

  const [activeIndex, setactiveIndex] = useState("");
  const [activeIndexV, setactiveIndexV] = useState("");
  const [activeIndexM, setactiveIndexM] = useState("");

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const inspectionColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
    },

    {
      field: "created_at",
      headerName: "Last Inspected On",
      width: 220,
      renderCell: (params) => {
        const timestamp = params?.row?.last_inspected_at;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.last_inspected_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "unresolved_tyre_issue",
      headerName: "Unresolved Tyre Issues",
      width: 230,
      renderCell: (params) => {
        const data = params?.row?.unresolved_tyre_issue;
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isEmpty(data) ? (
              Object.keys(data).map((item) => {
                return (
                  <div>
                    <Typography>
                      {item} : {data[item]}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <Typography>-</Typography>
            )}
          </div>
        );
      },
    },
    {
      field: "unresolved_mechanical_issue",
      headerName: "Unresolved Mechanical Issues",
      width: 280,
      renderCell: (params) => {
        const data = params?.row?.unresolved_mechanical_issue;
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!isEmpty(data) ? (
              Object.keys(data).map((item) => {
                return (
                  <div>
                    <Typography>
                      {item} : {data[item]}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <Typography>-</Typography>
            )}
          </div>
        );
      },
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 220,
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "vehicle_type_id",
      headerName: "Vehicle Type",
      width: 220,
      renderCell: (params) => {
        let array = params?.row?.vehicle_type_id;
        let res =
          !isNull(array) &&
          dropDownData?.vehicle_type?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_brand_id",
      headerName: "Vehicle Brand",
      width: 220,
      renderCell: (params) => {
        let array = params?.row?.vehicle_brand_id;
        let res =
          !isNull(array) &&
          dropDownData?.vehicle_brand?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "driver_name",
      headerName: "Driver's Name",
      width: 220,
      renderCell: (params) => {
        return params?.row?.driver_name || "-";
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 220,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: params?.row?.reason === "Overdue Inspection" && "red",
            }}
          >
            {params?.row?.reason}
          </Typography>
        );
      },
    },
  ];

  const pendingReadingColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoV === 1 ? index : (pageNoV - 1) * recordV + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 220,
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 220,
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "driver_name",
      headerName: "Driver's Name",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return params?.row?.driver_name || "-";
      },
    },
    {
      field: "inspection_date",
      headerName: "Last Inspected On",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.inspection_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  const pendingMileageColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoM === 1 ? index : (pageNoM - 1) * recordM + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 220,
      renderCell: (params) => {
        return <Typography>{params?.row?.tyre_serial_no}</Typography>;
      },
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 220,
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 220,
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "tyre_condition",
      headerName: "Current Condition",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 220,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 140,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Discription",
      width: 220,
      renderCell: (params) => {
        return params?.row?.tyre_pattern_size || "-";
      },
    },
    {
      field: "tyre_position",
      headerName: "Current Position",
      width: 220,
    },
    {
      field: "axle_type",
      headerName: "Current Axle Type",
      width: 220,
    },
  ];

  const DataGridRender = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        getRowHeight={() => "auto"}
        rows={inspectionListRows?.data}
        columns={inspectionColumns}
        // onCellClick={(params) => {
        //   navigate("/dashboard/FleetManagement", {
        //     state: {
        //       set: "dashboardDetails",
        //       fleet_id: params?.row?.fleet_id,
        //       fleet_name: params?.row?.fleet_name,
        //       vehicle_no: params?.row?.vehicle_no,
        //       id: params?.row?.vehicle_id,
        //       no_of_tyres: params?.row?.no_of_tyres,
        //       vehicle_brand_id: params?.row?.vehicle_brand_id,
        //       vehicle_type_id: params?.row?.vehicle_type_id,
        //       driver_name: params?.row?.driver_name,
        //       driver_phone: params?.row?.driver_phone,
        //       no_of_rows: params?.row?.no_of_rows,
        //     },
        //   });
        // }}
        onCellClick={(params) => {
          navigate("/dashboard/Inspection", {
            state: { set: "addInspection", data: params?.row },
          });
        }}
        pageSize={record}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
      />
    );
  }, [inspectionListRows]);

  const DataGridRender1 = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={pendingReadingListRows?.data}
        columns={pendingReadingColumns}
        pageSize={recordV}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        showColumnRightBorder
        disableSelectionOnClick
        onCellClick={(params) => {
          navigate("/dashboard/Inspection", {
            state: {
              set: "dashboardDetails",
              fleet_id: params?.row?.fleet_id,
              fleet_name: params?.row?.fleet_name,
              vehicle_id: params?.row?.vehicle_id,
              date: params?.row?.inspection_date,
              vehicle_no: params?.row?.vehicle_no,
            },
          });
        }}
      />
    );
  }, [pendingReadingListRows]);

  const DataGridRender2 = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={pendingMileageListRows?.data}
        columns={pendingMileageColumns}
        pageSize={recordM}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        onCellClick={(params) => {
          navigate("/dashboard/TyreInventory", {
            state: {
              set: "dashboardDetails",
              tyre_serial_no: params?.row?.tyre_serial_no,
            },
          });
        }}
        showColumnRightBorder
        disableSelectionOnClick
      />
    );
  }, [pendingMileageListRows]);

  // intialRender used for stop multiple time apiCall
  const initialRender = useRef(true);
  const initialRenderReading = useRef(true);
  const initialRenderMileage = useRef(true);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      if (token !== "") {
        getinspectionList(false);
      }
    }
  }, [record, pageNo, filter, token, selectFleet]);

  useEffect(() => {
    setPageNo(1);
  }, [record]);

  useEffect(() => {
    setPageNoV(1);
  }, [recordV]);

  useEffect(() => {
    setPageNoM(1);
  }, [recordM]);

  useEffect(() => {
    if (initialRenderReading?.current) {
      initialRenderReading.current = false;
    } else {
      if (token !== "") {
        getpendingRList(false);
      }
    }
  }, [recordV, pageNoV, token, filterV]);

  useEffect(() => {
    if (initialRenderMileage?.current) {
      initialRenderMileage.current = false;
    } else {
      if (token !== "") {
        getpendingMList(false);
      }
    }
  }, [recordM, pageNoM, token, filterM]);

  useEffect(() => {
    if (token !== "") {
      isEmpty(searchFilter) && getinspectionList(false);
    }
  }, [searchFilter]);

  useEffect(() => {
    if (token !== "") {
      isEmpty(searchFilterV) && getpendingRList(false);
    }
  }, [searchFilterV]);

  useEffect(() => {
    if (token !== "") {
      isEmpty(searchFilterM) && getpendingMList(false);
    }
  }, [searchFilterM]);

  useEffect(() => {
    setactiveIndex(null);
  }, [pageNo, record]);

  useEffect(() => {
    setactiveIndexV(null);
  }, [pageNoV, recordV]);

  useEffect(() => {
    setactiveIndexM(null);
  }, [pageNoM, recordM]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);
  useEffect(() => {
    if (activeIndexV !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndexV}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndexV]);
  useEffect(() => {
    if (activeIndexM !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndexM}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndexM]);

  useEffect(() => {
    if (!isTablet) {
      const section = document.querySelector(`#scroll`);
      section.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [pageNo, pageNoV, pageNoM]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Dashboard`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getinspectionList(bool, v) {
    v && setReportLoader(true);
    setactiveIndex(null);
    !v && setPageLoad(true);

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.attentionvehicleList
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&filter=${filter || ""}&is_download=${v || ""}&fleet_id=${
          selectFleet.replaceAll("&", "%26") || ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadInspectionData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setinspectionListRows(listObj);
          }
        v && auditSave("Inspection List");
      } else {
        setinspectionListRows({});
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  async function getpendingRList(bool, v) {
    v && setReportLoader(true);
    setactiveIndexV(null);
    !v && setPageLoadV(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.pendingReadingList
        }?per_page=${recordV}&page=${pageNoV}&search=${
          bool ? "" : searchFilterV
        }&is_download=${v ? v : ""}&filter=${filterIdV}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadReadingData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setpendingReadingListRows(listObj);
          }
        v && auditSave("Pending Reading List");
      } else {
        setpendingReadingListRows({});
      }
      setPageLoadV(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoadV(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getpendingMList(bool, v) {
    v && setReportLoader(true);
    setactiveIndexM(null);
    !v && setPageLoadM(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.pendingMileageList
        }?per_page=${recordM}&page=${pageNoM}&search=${
          bool ? "" : searchFilterM
        }&is_download=${v ? v : ""}&filter=${filterIdM}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadMilageData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setpendingMileageListRows(listObj);
          }
        v && auditSave("Pending Mileage List");
      } else {
        setpendingMileageListRows({});
      }

      setPageLoadM(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoadM(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  function downloadInspectionData(data) {
    const newData = data.map((item, index) => {
      let vehicleType =
        !isNull(item?.vehicle_type_id) &&
        dropDownData?.vehicle_type?.find(
          (v) => item?.vehicle_type_id?.toString() === v?.id?.toString()
        );

      let vehicleBrand =
        !isNull(item?.vehicle_brand_id) &&
        dropDownData?.vehicle_brand?.find(
          (v) => item?.vehicle_brand_id?.toString() === v?.id?.toString()
        );

      const timestamp = item?.last_inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      let issueObj = item?.unresolved_tyre_issue;
      let arr = [];
      let obj = Object.keys(issueObj).map((item) => {
        arr.push(`${item} : ${issueObj[item]}`);
      });

      let issueObj1 = item?.unresolved_mechanical_issue;
      let arr1 = [];
      let obj1 = Object.keys(issueObj1).map((item) => {
        arr1.push(`${item} : ${issueObj1[item]}`);
      });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Last Inspected On": item?.last_inspected_at ? displayDate : "-",
        "Unresolved Tyre Issues": arr.toString() || "-",
        "Unresolved Mechanical Issues": arr1.toString() || "-",
        "No. of Tyres": `${item?.added_tyre_count}/${item?.no_of_tyres}` || "-",
        "Vehicle Type": vehicleType?.label || "-",
        "Vehicle Brand": vehicleBrand?.label || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Driver's Name": item?.driver_name || "-",
        Reason: item?.reason,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inspection List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection List.xlsx");
  }

  function downloadReadingData(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.last_inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "No. of Tyres": `${item?.added_tyre_count}/${item?.no_of_tyres}` || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Driver's Name": item?.driver_name || "-",
        "Last Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Pending Reading List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Pending Reading List.xlsx");
  }

  function downloadMilageData(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Tyre Serial No.": item?.tyre_serial_no || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Current Condition": item?.tyre_condition || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Discription": item?.tyre_pattern_size || "-",
        "Current Position": item?.tyre_position || "-",
        "Current Axle Type": item?.axle_type || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Pending Mileage List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Pending Mileage List.xlsx");
  }

  const getNoOfTyres = (index) => {
    return (
      <>
        <span
          style={{
            color:
              inspectionListRows?.data[index]?.added_tyre_count != (0 || "")
                ? "red"
                : "",
          }}
        >
          {inspectionListRows?.data[index]?.added_tyre_count}
        </span>
        <span>/{inspectionListRows?.data[index]?.no_of_tyres}</span>
      </>
    );
  };

  const getVehicleType = (index) => {
    let array = inspectionListRows?.data[index]?.vehicle_type_id;
    let res =
      !isNull(array) &&
      dropDownData?.vehicle_type?.find(
        (item) => array?.toString() === item?.id?.toString()
      );
    return <Typography>{res?.label}</Typography>;
  };

  const getVehicleBrand = (index) => {
    let array = inspectionListRows?.data[index]?.vehicle_brand_id;
    let res =
      !isNull(array) &&
      dropDownData?.vehicle_brand?.find(
        (item) => array?.toString() === item?.id?.toString()
      );
    return <Typography>{res?.label}</Typography>;
  };

  const getLastInspectedOn = (index) => {
    const timestamp = inspectionListRows?.data[index]?.last_inspected_at;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const getLastInspectedOnV = (index) => {
    const timestamp = pendingReadingListRows?.data[index]?.inspection_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const getCurrentConditionM = (index) => {
    return (
      <span
        style={{
          backgroundColor:
            pendingMileageListRows?.data[index]?.tyre_condition === "Average"
              ? "#FFFDD9"
              : pendingMileageListRows?.data[index]?.tyre_condition === "Good"
              ? "#D9FFE4"
              : pendingMileageListRows?.data[index]?.tyre_condition === "Bad"
              ? "#FFD9D9"
              : "",
          padding: 8,
          borderRadius: 4,
        }}
      >
        {pendingMileageListRows?.data[index]?.tyre_condition || "-"}
      </span>
    );
  };

  function issue(item) {
    let obj = item?.unresolved_tyre_issue;
    let arr = [];
    isObject(obj) &&
      Object.keys(obj).map((v) => {
        arr.push(`${v} : ${obj[v]}`);
      });
    return (
      <div style={{ marginBottom: 5 }}>
        {isEmpty(obj) ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{arr.toString()}</Typography>
        )}
      </div>
    );
  }

  function mechIssue(item) {
    let obj = item?.unresolved_mechanical_issue;
    let arr = [];

    isObject(obj) &&
      Object.keys(obj).map((v) => {
        arr.push(`${v} : ${obj[v]}`);
      });
    return isEmpty(obj) ? (
      <Typography>-</Typography>
    ) : (
      <Typography>{arr.toString()}</Typography>
    );
  }

  function resetFilterData() {
    setFilter("");
    setSearchFilter("");
    setSelectFleet("");
    setPageNo(1);
  }
  function resetFilterDataV() {
    setSearchFilterV("");
    setPageNoV(1);
    setFilterIdV("");
    setFilterV("");
  }
  function resetFilterDataM() {
    setSearchFilterM("");
    setPageNoM(1);
    setFilterIdM("");
    setFilterM("");
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.fleetMain}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ backgroundColor: color.primary, padding: "15px" }}
          >
            <Typography variant="tableTitle" style={{ color: color.white }}>
              Vehicles That Need Attention
            </Typography>
            <Grid item display="flex" wrap="nowrap">
              <Button
                disabled={isOnline ? false : true}
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                onClick={() => {
                  tabValue === 0
                    ? getinspectionList(false, 1)
                    : tabValue === 1
                    ? getpendingRList(false, 1)
                    : getpendingMList(false, 1);
                }}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <div style={{ padding: 20 }}>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Typography variant="tableTitle">
                {tabValue === 1
                  ? "Pending Reading"
                  : tabValue === 2
                  ? "Pending Mileage"
                  : "Inspection"}
              </Typography>
              <Button
                variant="contained"
                style={{
                  backgroundColor: color.white,
                  color: "#D8D8D8",
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
              </Button>
              <Popover
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                id={id}
                open={pop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: isTablet ? "center" : "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ListItemButton
                  selected={tabValue === 0}
                  onClick={(event) => {
                    setPageNo(1);
                    setRecord(10);
                    setTabValue(0);
                    setAnchorEl(null);
                    setFilter("");
                    setSelectFleet("");
                  }}
                >
                  Inspection
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 1}
                  onClick={() => {
                    setPageNoV(1);
                    setRecordV(10);
                    setTabValue(1);
                    setAnchorEl(null);
                    setFilterIdV("");
                    setFilterV("");
                  }}
                >
                  Pending Reading
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 2}
                  onClick={() => {
                    setPageNoM(1);
                    setRecordM(10);
                    setTabValue(2);
                    setAnchorEl(null);
                    setFilterIdM("");
                    setFilterM("");
                  }}
                >
                  Pending Mileage
                </ListItemButton>
              </Popover>
            </Grid>
            <Grid style={{ marginTop: 10 }}>
              {tabValue === 0 ? (
                <div>
                  <Grid container gap={1} justifyContent={"space-between"}>
                    <Grid item>
                      <Select
                        value={selectFleet}
                        onChange={(v) => {
                          setPageNo(1);
                          setSelectFleet(v.target.value);
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={selectFleet === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Fleet
                        </MenuItem>
                        {fleetData.map((item, index) => {
                          return (
                            <MenuItem
                              style={{ display: "flex" }}
                              key={index}
                              value={item?.label}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Select
                        value={filter}
                        onChange={(v) => {
                          setPageNo(1);
                          setFilter(v.target.value);
                        }}
                        onOpen={() => {
                          if (isEmpty(filter.toString())) {
                            setbrandList(false);
                            settypeList(false);
                          }
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={
                          filter === ""
                            ? { color: "#A2A2A2", marginLeft: 10 }
                            : { marginLeft: 10 }
                        }
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Filter
                        </MenuItem>
                        <ListItemButton
                          onClick={() => {
                            settypeList(!typeList);
                            setbrandList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Vehicle Type</Typography>
                          {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {dropDownData?.vehicle_type?.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: typeList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                        <ListItemButton
                          onClick={() => {
                            setbrandList(!brandList);
                            settypeList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Vehicle Brand</Typography>
                          {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {dropDownData?.vehicle_brand?.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: brandList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item></Grid>
                    <Grid item>
                      <Select
                        fullWidth
                        value={record}
                        onChange={(v) => {
                          setPageNo(1);
                          setRecord(v.target.value);
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                    wrap="nowrap"
                  >
                    <TextField
                      placeholder="Search"
                      className={styles.inputFieldStyle}
                      value={searchFilter}
                      onChange={(v) => {
                        isEmpty(v.target.value) && getinspectionList(true);
                        setSearchFilter(v?.target?.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ lineHeight: 0 }}>
                              <Search style={{ fontSize: 20 }} />
                            </div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ lineHeight: 0, cursor: "pointer" }}>
                              <Tooltip
                                title="Search by Vehicle No."
                                placement="bottom"
                                arrow
                              >
                                <InfoIcon style={{ fontSize: 20 }} />
                              </Tooltip>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(v) => {
                        if (v.key === "Enter") {
                          if (!isEmpty(searchFilter)) {
                            setPageNo(1);
                            getinspectionList(false);
                          }
                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: color.secondary,
                        marginLeft: 10,
                      }}
                      onClick={() => {
                        if (!isEmpty(searchFilter)) {
                          setPageNo(1);
                          getinspectionList(false);
                        }
                      }}
                    >
                      <Search />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: 10,
                      }}
                      disabled={!filter && !selectFleet && !searchFilter}
                      onClick={resetFilterData}
                    >
                      <ResetIcon />
                    </Button>
                  </Grid>
                  {pageLoad ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(inspectionListRows?.data) &&
                    !isEmpty(inspectionListRows?.data) ? (
                    <>
                      <div style={{ marginTop: 10 }}>{DataGridRender}</div>
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            inspectionListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNo}
                          boundaryCount={2}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            setPageNo(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              ) : null}
              {tabValue === 1 ? (
                <div>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    gap={"10px"}
                  >
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                      gap="10px"
                    >
                      <Select
                        value={filterV}
                        onChange={(v) => {
                          setPageNoV(1);
                          setFilterV(v.target.value);
                        }}
                        onOpen={() => {
                          if (isEmpty(filterV.toString())) {
                            setFleetList(false);
                          }
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={filterV === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Filter
                        </MenuItem>
                        <ListItemButton
                          onClick={() => {
                            setFleetList(!fleetList);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Fleet</Typography>
                          {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {fleetData.map((item, index) => {
                          return (
                            <MenuItem
                              style={{ display: fleetList ? "flex" : "none" }}
                              key={index}
                              value={item?.label}
                              onClick={() => {
                                setFilterIdV(item?.id);
                              }}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          className={styles.inputFieldStyle}
                          value={searchFilterV}
                          onChange={(v) => {
                            isEmpty(v.target.value) && getpendingRList(true);
                            setSearchFilterV(v?.target?.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div style={{ lineHeight: 0 }}>
                                  <Search style={{ fontSize: 20 }} />
                                </div>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{ lineHeight: 0, cursor: "pointer" }}
                                >
                                  <Tooltip
                                    title="Search by Vehicle No."
                                    placement="bottom"
                                    arrow
                                  >
                                    <InfoIcon style={{ fontSize: 20 }} />
                                  </Tooltip>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          onKeyDown={(v) => {
                            if (v.key === "Enter") {
                              if (!isEmpty(searchFilterV)) {
                                setPageNoV(1);
                                getpendingRList(false);
                              }
                            }
                          }}
                        />
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: color.secondary }}
                          onClick={() => {
                            if (!isEmpty(searchFilterV)) {
                              setPageNoV(1);
                              getpendingRList(false);
                            }
                          }}
                        >
                          <Search />
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            marginLeft: 10,
                          }}
                          disabled={filterV === "" && isEmpty(searchFilterV)}
                          onClick={resetFilterDataV}
                        >
                          <ResetIcon />
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        value={recordV}
                        onChange={(v) => {
                          setPageNoV(1);
                          setRecordV(v.target.value);
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  {pageLoadV ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(pendingReadingListRows?.data) &&
                    !isEmpty(pendingReadingListRows?.data) ? (
                    <>
                      <div style={{ marginTop: 10 }}>{DataGridRender1}</div>
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            pendingReadingListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNoV}
                          boundaryCount={2}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            setPageNoV(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              ) : null}
              {tabValue === 2 ? (
                <div>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                      gap="10px"
                    >
                      <Select
                        value={filterM}
                        onChange={(v) => {
                          setPageNoM(1);
                          setFilterM(v.target.value);
                        }}
                        onOpen={() => {
                          if (isEmpty(filterM.toString())) {
                            setFleetList(false);
                          }
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={filterM === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Filter
                        </MenuItem>
                        <ListItemButton
                          onClick={() => {
                            setFleetList(!fleetList);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Fleet</Typography>
                          {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {fleetData.map((item, index) => {
                          return (
                            <MenuItem
                              style={{ display: fleetList ? "flex" : "none" }}
                              key={index}
                              value={item?.label}
                              onClick={() => {
                                setFilterIdM(item?.id);
                              }}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Grid item>
                        <TextField
                          fullWidth
                          placeholder="Search"
                          className={styles.inputFieldStyle}
                          value={searchFilterM}
                          onChange={(v) => {
                            isEmpty(v.target.value) && getpendingRList(true);
                            setSearchFilterM(v?.target?.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div style={{ lineHeight: 0 }}>
                                  <Search style={{ fontSize: 20 }} />
                                </div>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{ lineHeight: 0, cursor: "pointer" }}
                                >
                                  <Tooltip
                                    title="Search by Vehicle No."
                                    placement="bottom"
                                    arrow
                                  >
                                    <InfoIcon style={{ fontSize: 20 }} />
                                  </Tooltip>
                                </div>
                              </InputAdornment>
                            ),
                          }}
                          onKeyDown={(v) => {
                            if (v.key === "Enter") {
                              if (!isEmpty(searchFilterM)) {
                                setPageNoM(1);
                                getpendingMList(false);
                              }
                            }
                          }}
                        />
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: color.secondary }}
                          onClick={() => {
                            if (!isEmpty(searchFilterM)) {
                              setPageNoM(1);
                              getpendingMList(false);
                            }
                          }}
                        >
                          <Search />
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            marginLeft: 10,
                          }}
                          disabled={
                            filterM === "" && isEmpty(searchFilterM)
                              ? true
                              : false
                          }
                          onClick={resetFilterDataM}
                        >
                          <ResetIcon />
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={1.6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        fullWidth
                        value={recordM}
                        onChange={(v) => {
                          setPageNoM(1);
                          setRecordM(v.target.value);
                        }}
                        style={{ marginLeft: 20 }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  {pageLoadM ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(pendingMileageListRows?.data) &&
                    !isEmpty(pendingMileageListRows?.data) ? (
                    <>
                      <div style={{ marginTop: 10 }}>{DataGridRender2}</div>
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            pendingMileageListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNoM}
                          boundaryCount={2}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            setPageNoM(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              ) : null}
            </Grid>
          </div>
        </div>
      ) : (
        <div className={styles.fleetMain}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{
              backgroundColor: color.primary,
              padding: 10,
              minHeight: 60,
            }}
          >
            <Typography
              variant="tableTitle"
              style={{ color: color.white }}
              id="scroll"
            >
              Vehicles That Need Attention
            </Typography>
            <Grid item display="flex" wrap="nowrap">
              <Button
                disabled={isOnline ? false : true}
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                onClick={() => {
                  tabValue === 0
                    ? getinspectionList(false, 1)
                    : tabValue === 1
                    ? getpendingRList(false, 1)
                    : getpendingMList(false, 1);
                }}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <div style={{ padding: 10 }}>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Typography variant="tableTitle">
                {tabValue === 1
                  ? "Pending Reading"
                  : tabValue === 2
                  ? "Pending Mileage"
                  : "Inspection"}
              </Typography>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#BBBBBB",
                  color: color.secondary,
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert />
              </Button>
              <Popover
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                id={id}
                open={pop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: isTablet ? "center" : "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ListItemButton
                  selected={tabValue === 0}
                  onClick={(event) => {
                    setPageNo(1);
                    setRecord(10);
                    setTabValue(0);
                    setAnchorEl(null);
                    setFilter("");
                    setSelectFleet("");
                  }}
                >
                  Inspection
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 1}
                  onClick={() => {
                    setPageNoV(1);
                    setRecordV(10);
                    setTabValue(1);
                    setAnchorEl(null);
                    setFilterV("");
                    setFilterIdV("");
                  }}
                >
                  Pending Reading
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 2}
                  onClick={() => {
                    setPageNoM(1);
                    setRecordM(10);
                    setTabValue(2);
                    setAnchorEl(null);
                    setFilterM("");
                    setFilterIdM("");
                  }}
                >
                  Pending Mileage
                </ListItemButton>
              </Popover>
            </Grid>
            <Grid style={{ marginTop: 14 }}>
              {tabValue === 0 ? (
                <div>
                  <Grid container gap={1} justifyContent={"space-between"}>
                    <Grid item>
                      <Select
                        value={selectFleet}
                        onChange={(v) => {
                          setPageNo(1);
                          setSelectFleet(v.target.value);
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={selectFleet === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Fleet
                        </MenuItem>
                        {fleetData.map((item, index) => {
                          return (
                            <MenuItem
                              style={{ display: "flex" }}
                              key={index}
                              value={item?.label}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item>
                      <Select
                        value={filter}
                        onChange={(v) => {
                          setPageNo(1);
                          setFilter(v.target.value);
                        }}
                        onOpen={() => {
                          if (isEmpty(filter.toString())) {
                            setbrandList(false);
                            settypeList(false);
                          }
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={filter === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Filter
                        </MenuItem>
                        <ListItemButton
                          onClick={() => {
                            settypeList(!typeList);
                            setbrandList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Vehicle Type</Typography>
                          {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {dropDownData?.vehicle_type?.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: typeList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                        <ListItemButton
                          onClick={() => {
                            setbrandList(!brandList);
                            settypeList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Vehicle Brand</Typography>
                          {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {dropDownData?.vehicle_brand?.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: brandList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item style={{ marginLeft: "auto" }}>
                      <Select
                        fullWidth
                        value={record}
                        onChange={(v) => {
                          setPageNo(1);
                          setRecord(v.target.value);
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                    wrap="nowrap"
                  >
                    <TextField
                      placeholder="Search"
                      className={styles.inputFieldStyle}
                      value={searchFilter}
                      onChange={(v) => {
                        isEmpty(v.target.value) && getinspectionList(true);
                        setSearchFilter(v?.target?.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ lineHeight: 0 }}>
                              <Search style={{ fontSize: 20 }} />
                            </div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ lineHeight: 0, cursor: "pointer" }}>
                              <Tooltip
                                title="Search by Vehicle No."
                                placement="bottom"
                                arrow
                              >
                                <InfoIcon style={{ fontSize: 20 }} />
                              </Tooltip>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(v) => {
                        if (v.key === "Enter") {
                          if (!isEmpty(searchFilter)) {
                            setPageNo(1);
                            getinspectionList(false);
                          }
                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: color.secondary,
                        marginLeft: 10,
                      }}
                      onClick={() => {
                        if (!isEmpty(searchFilter)) {
                          setPageNo(1);
                          getinspectionList(false);
                        }
                      }}
                    >
                      <Search />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: 10,
                      }}
                      disabled={!filter && !selectFleet && !searchFilter}
                      onClick={resetFilterData}
                    >
                      <ResetIcon />
                    </Button>
                  </Grid>
                  {pageLoad ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(inspectionListRows?.data) &&
                    !isEmpty(inspectionListRows?.data) ? (
                    <>
                      {inspectionListRows?.data?.map((item, index) => {
                        return (
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <Grid
                              item
                              container
                              id={`jump_to_me${index}`}
                              style={{
                                marginTop: 20,
                                border: `1px solid ${color.bordercolor}`,
                                alignItems: "center",
                                overflow: "hidden",
                                boxShadow: color.shadow,
                              }}
                            >
                              {activeIndex !== index ? (
                                <Grid item container>
                                  <Table className={styles.customtableMobile}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle No.
                                        </TableCell>
                                        <TableCell variant="head">
                                          Last Inspected On
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_no || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getLastInspectedOn(index) || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Reason
                                        </TableCell>
                                        <TableCell variant="head">
                                          No. of Tyres
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          <Typography
                                            style={{
                                              color:
                                                item?.reason ===
                                                  "Overdue Inspection" && "red",
                                            }}
                                          >
                                            {item?.reason || "-"}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getNoOfTyres(index) || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </Grid>
                              ) : (
                                <Grid item container>
                                  <Table className={styles.customtableMobile}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle No.
                                        </TableCell>
                                        <TableCell variant="head">
                                          Last Inspected On
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_no || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getLastInspectedOn(index) || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Reason
                                        </TableCell>
                                        <TableCell variant="head">
                                          No. of Tyres
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          <Typography
                                            style={{
                                              color:
                                                item?.reason ===
                                                  "Overdue Inspection" && "red",
                                            }}
                                          >
                                            {item?.reason || "-"}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getNoOfTyres(index) || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Unresolved Tyre Issues
                                        </TableCell>
                                        <TableCell variant="head">
                                          Unresolved Mechanical Issues
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <Typography
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            {issue(item)}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>{mechIssue(item)}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle Brand
                                        </TableCell>
                                        <TableCell variant="head">
                                          Vehicle Model
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getVehicleBrand(index) || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_model || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle Type
                                        </TableCell>
                                        <TableCell variant="head">
                                          Fleet Name
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getVehicleType(index) || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.fleet_name || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Driver's Name
                                        </TableCell>
                                        <TableCell rowSpan={2} align="right">
                                          <Button
                                            style={{
                                              backgroundColor: color.primary,
                                              marginRight: 10,
                                            }}
                                            // onClick={(index) =>
                                            //   navigate(
                                            //     "/dashboard/FleetManagement",
                                            //     {
                                            //       state: {
                                            //         set: "dashboardDetails",
                                            //         fleet_id: item?.fleet_id,
                                            //         fleet_name:
                                            //           item?.fleet_name,
                                            //         vehicle_no:
                                            //           item?.vehicle_no,
                                            //         id: item?.vehicle_id,
                                            //         no_of_tyres:
                                            //           item?.no_of_tyres,
                                            //         vehicle_brand_id:
                                            //           item?.vehicle_brand_id,
                                            //         vehicle_type_id:
                                            //           item?.vehicle_type_id,
                                            //         driver_name:
                                            //           item?.driver_name,
                                            //         driver_phone:
                                            //           item?.driver_phone,
                                            //         no_of_rows:
                                            //           item?.no_of_rows,
                                            //       },
                                            //     }
                                            //   )
                                            // }
                                            onClick={() => {
                                              navigate(
                                                "/dashboard/Inspection",
                                                {
                                                  state: {
                                                    set: "addInspection",
                                                    data: item,
                                                  },
                                                }
                                              );
                                            }}
                                          >
                                            <Visibility />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.driver_name || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </Grid>
                              )}
                              <div
                                onClick={(v) => {
                                  if (activeIndex === index) {
                                    setactiveIndex(null);
                                  } else {
                                    setactiveIndex(index);
                                  }
                                }}
                                style={{
                                  position: "absolute",
                                  bottom: -18,
                                  left: "45%",
                                }}
                              >
                                {activeIndex === index ? (
                                  <div style={{ transform: "rotate(180deg)" }}>
                                    <TableToggle />
                                  </div>
                                ) : (
                                  <TableToggle />
                                )}
                              </div>
                            </Grid>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          marginTop: 25,
                          marginBottom: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            inspectionListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNo}
                          boundaryCount={1}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            setPageNo(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              ) : null}

              {tabValue === 1 ? (
                <div>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    gap={1}
                    style={{ marginBottom: 10 }}
                  >
                    <Select
                      value={filterV}
                      onChange={(v) => {
                        setPageNoV(1);
                        setFilterV(v.target.value);
                      }}
                      onOpen={() => {
                        if (isEmpty(filterV.toString())) {
                          setFleetList(false);
                        }
                      }}
                      displayEmpty
                      IconComponent={FilterAlt}
                      style={filterV === "" ? { color: "#A2A2A2" } : {}}
                      classes={{
                        iconOpen: styles.iconOpen,
                      }}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Filter
                      </MenuItem>
                      <ListItemButton
                        onClick={() => {
                          setFleetList(!fleetList);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Fleet</Typography>
                        {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                      {fleetData.map((item, index) => {
                        return (
                          <MenuItem
                            style={{ display: fleetList ? "flex" : "none" }}
                            key={index}
                            value={item?.label}
                            onClick={() => {
                              setFilterIdV(item?.id);
                            }}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Select
                      value={recordV}
                      onChange={(v) => {
                        setPageNoV(1);
                        setRecordV(v.target.value);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid item display="flex" alignItems="center" wrap="nowrap">
                      <TextField
                        placeholder="Search"
                        className={styles.inputFieldStyle}
                        value={searchFilterV}
                        onChange={(v) => {
                          isEmpty(v.target.value) && getpendingRList(true);
                          setSearchFilterV(v?.target?.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ lineHeight: 0 }}>
                                <Search style={{ fontSize: 20 }} />
                              </div>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ lineHeight: 0, cursor: "pointer" }}>
                                <Tooltip
                                  title="Search by Vehicle No."
                                  placement="bottom"
                                  arrow
                                >
                                  <InfoIcon style={{ fontSize: 20 }} />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(v) => {
                          if (v.key === "Enter") {
                            if (!isEmpty(searchFilterV)) {
                              setPageNoV(1);
                              getpendingRList(false);
                            }
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: color.secondary,
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          if (!isEmpty(searchFilterV)) {
                            setPageNoV(1);
                            getpendingRList(false);
                          }
                        }}
                      >
                        <Search />
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          marginLeft: 10,
                        }}
                        disabled={
                          filterV === "" && isEmpty(searchFilterV)
                            ? true
                            : false
                        }
                        onClick={resetFilterDataV}
                      >
                        <ResetIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  {pageLoadV ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(pendingReadingListRows?.data) &&
                    !isEmpty(pendingReadingListRows?.data) ? (
                    <>
                      {pendingReadingListRows?.data?.map((item, index) => {
                        return (
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <Grid
                              item
                              id={`jump_to_me${index}`}
                              container
                              style={{
                                marginTop: 20,
                                border: `1px solid ${color.bordercolor}`,
                                alignItems: "center",
                                overflow: "hidden",
                                boxShadow: color.shadow,
                              }}
                            >
                              {activeIndexV !== index ? (
                                <Grid item container>
                                  <Table className={styles.customtableMobile}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle No.
                                        </TableCell>
                                        <TableCell variant="head">
                                          Last Inspected On
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_no || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getLastInspectedOnV(index) || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          No. of Tyres
                                        </TableCell>
                                        <TableCell variant="head">
                                          Vehicle Type
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          <span
                                            style={{
                                              color:
                                                item?.added_tyre_count !=
                                                (0 || "")
                                                  ? "red"
                                                  : "",
                                            }}
                                          >
                                            {item?.added_tyre_count}
                                          </span>
                                          <span>/{item?.no_of_tyres}</span>
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_type || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </Grid>
                              ) : (
                                <Grid item container>
                                  <Table className={styles.customtableMobile}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle No.
                                        </TableCell>
                                        <TableCell variant="head">
                                          Last Inspected On
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_no || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {getLastInspectedOnV(index) || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          No. of Tyres
                                        </TableCell>
                                        <TableCell variant="head">
                                          Vehicle Type
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          <span
                                            style={{
                                              color:
                                                item?.added_tyre_count !=
                                                (0 || "")
                                                  ? "red"
                                                  : "",
                                            }}
                                          >
                                            {item?.added_tyre_count}
                                          </span>
                                          <span>/{item?.no_of_tyres}</span>
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_type || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle Brand
                                        </TableCell>
                                        <TableCell variant="head">
                                          Vehicle Model
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_brand || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_model || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Driver's Name
                                        </TableCell>
                                        <TableCell variant="head">
                                          Fleet Name
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.driver_name || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.fleet_name || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          colSpan={2}
                                          rowSpan={2}
                                          align="right"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          <Button
                                            style={{
                                              backgroundColor: color.primary,
                                              marginRight: 10,
                                            }}
                                            onClick={() => {
                                              navigate(
                                                "/dashboard/Inspection",
                                                {
                                                  state: {
                                                    set: "dashboardDetails",
                                                    fleet_id: item?.fleet_id,
                                                    fleet_name:
                                                      item?.fleet_name,
                                                    vehicle_id:
                                                      item?.vehicle_id,
                                                    date: item?.inspection_date,
                                                    vehicle_no:
                                                      item?.vehicle_no,
                                                  },
                                                }
                                              );
                                            }}
                                          >
                                            <Visibility />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </Grid>
                              )}
                              <div
                                onClick={(v) => {
                                  if (activeIndexV === index) {
                                    setactiveIndexV(null);
                                  } else {
                                    setactiveIndexV(index);
                                  }
                                }}
                                style={{
                                  position: "absolute",
                                  bottom: -18,
                                  left: "45%",
                                }}
                              >
                                {activeIndexV === index ? (
                                  <div style={{ transform: "rotate(180deg)" }}>
                                    <TableToggle />
                                  </div>
                                ) : (
                                  <TableToggle />
                                )}
                              </div>
                            </Grid>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            pendingReadingListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNoV}
                          boundaryCount={1}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            setPageNoV(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              ) : null}

              {tabValue === 2 ? (
                <div>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    gap={1}
                    style={{ marginBottom: 10 }}
                  >
                    <Select
                      value={filterM}
                      onChange={(v) => {
                        setPageNoM(1);
                        setFilterM(v.target.value);
                      }}
                      onOpen={() => {
                        if (isEmpty(filterM.toString())) {
                          setFleetList(false);
                        }
                      }}
                      displayEmpty
                      IconComponent={FilterAlt}
                      style={filterM === "" ? { color: "#A2A2A2" } : {}}
                      classes={{
                        iconOpen: styles.iconOpen,
                      }}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Filter
                      </MenuItem>
                      <ListItemButton
                        onClick={() => {
                          setFleetList(!fleetList);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Fleet</Typography>
                        {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                      {fleetData.map((item, index) => {
                        return (
                          <MenuItem
                            style={{ display: fleetList ? "flex" : "none" }}
                            key={index}
                            value={item?.label}
                            onClick={() => {
                              setFilterIdM(item?.id);
                            }}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Select
                      value={recordM}
                      onChange={(v) => {
                        setPageNoM(1);
                        setRecordM(v.target.value);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                      wrap="nowrap"
                    >
                      <TextField
                        fullWidth
                        placeholder="Search"
                        className={styles.inputFieldStyle}
                        value={searchFilterM}
                        onChange={(v) => {
                          isEmpty(v.target.value) && getpendingRList(true);
                          setSearchFilterM(v?.target?.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ lineHeight: 0 }}>
                                <Search style={{ fontSize: 20 }} />
                              </div>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ lineHeight: 0, cursor: "pointer" }}>
                                <Tooltip
                                  title="Search by Vehicle No."
                                  placement="bottom"
                                  arrow
                                >
                                  <InfoIcon style={{ fontSize: 20 }} />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={(v) => {
                          if (v.key === "Enter") {
                            if (!isEmpty(searchFilterM)) {
                              setPageNoM(1);
                              getpendingMList(false);
                            }
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: color.secondary,
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          if (!isEmpty(searchFilterM)) {
                            setPageNoM(1);
                            getpendingMList(false);
                          }
                        }}
                      >
                        <Search />
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          marginLeft: 10,
                        }}
                        disabled={
                          filterM === "" && isEmpty(searchFilterM)
                            ? true
                            : false
                        }
                        onClick={resetFilterDataM}
                      >
                        <ResetIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  {pageLoadM ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(pendingMileageListRows?.data) &&
                    !isEmpty(pendingMileageListRows?.data) ? (
                    <>
                      {pendingMileageListRows?.data?.map((item, index) => {
                        return (
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <Grid
                              item
                              id={`jump_to_me${index}`}
                              container
                              style={{
                                marginTop: 20,
                                border: `1px solid ${color.bordercolor}`,
                                alignItems: "center",
                                overflow: "hidden",
                                boxShadow: color.shadow,
                              }}
                            >
                              {activeIndexM !== index ? (
                                <Grid item container>
                                  <Table className={styles.customtableMobile}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle No.
                                        </TableCell>
                                        <TableCell variant="head">
                                          Tyre Serial No.
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_no || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.tyre_serial_no || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle Brand
                                        </TableCell>
                                        <TableCell variant="head">
                                          Vehicle Model
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_brand || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_model || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </Grid>
                              ) : (
                                <Grid item container>
                                  <Table className={styles.customtableMobile}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle No.
                                        </TableCell>
                                        <TableCell variant="head">
                                          Tyre Serial No.
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_no || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.tyre_serial_no || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Vehicle Brand
                                        </TableCell>
                                        <TableCell variant="head">
                                          Vehicle Model
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_brand || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_model || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Fleet Name
                                        </TableCell>

                                        <TableCell variant="head">
                                          Vehicle Type
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.fleet_name || "-"}
                                        </TableCell>

                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 5 }}
                                        >
                                          {item?.vehicle_type || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Current Condition
                                        </TableCell>
                                        <TableCell variant="head">
                                          Tyre Type
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 10 }}
                                        >
                                          {getCurrentConditionM(index) || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 10 }}
                                        >
                                          {item?.tyre_type || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Tyre Brand
                                        </TableCell>
                                        <TableCell variant="head">
                                          Item Discription
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 10 }}
                                        >
                                          {item?.tyre_brand || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 10 }}
                                        >
                                          {item?.tyre_pattern_size || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell variant="head">
                                          Current Position
                                        </TableCell>
                                        <TableCell variant="head">
                                          Current Axle Type
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 10 }}
                                        >
                                          {item?.tyre_position || "-"}
                                        </TableCell>
                                        <TableCell
                                          variant="body"
                                          style={{ paddingBottom: 10 }}
                                        >
                                          {item?.axle_type || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          colSpan={2}
                                          rowSpan={2}
                                          align="right"
                                        >
                                          <Button
                                            style={{
                                              backgroundColor: color.primary,
                                              marginRight: 10,
                                              marginBottom: 5,
                                            }}
                                            onClick={(index) =>
                                              navigate(
                                                "/dashboard/TyreInventory",
                                                {
                                                  state: {
                                                    set: "dashboardDetails",
                                                    tyre_serial_no:
                                                      item?.tyre_serial_no,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            <Visibility />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </Grid>
                              )}
                              <div
                                onClick={(v) => {
                                  if (activeIndexM === index) {
                                    setactiveIndexM(null);
                                  } else {
                                    setactiveIndexM(index);
                                  }
                                }}
                                style={{
                                  position: "absolute",
                                  bottom: -18,
                                  left: "45%",
                                }}
                              >
                                {activeIndexM === index ? (
                                  <div style={{ transform: "rotate(180deg)" }}>
                                    <TableToggle />
                                  </div>
                                ) : (
                                  <TableToggle />
                                )}
                              </div>
                            </Grid>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            pendingMileageListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNoM}
                          boundaryCount={1}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            setPageNoM(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              ) : null}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
