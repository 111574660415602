import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Modal,
  Pagination,
  Popover,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme";

import {
  Delete,
  Edit,
  FilterAlt,
  MoreVert,
  Search,
  SearchOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import {
  cloneDeep,
  flattenDeep,
  isArray,
  isEmpty,
  isNull,
  isObject,
  toNumber,
} from "lodash";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import { isMobile, isTablet } from "react-device-detect";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ConfirmDialog from "../../ConfirmDialog";
import authActions from "../../../Redux/reducers/auth/actions";
import MDateRange from "../../Modal/MDateRange";

export default function TabTyreInventoryView(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    handleSelectFleet = () => null,
    handleSearch = () => null,
    handledate = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    selectFleet = "",
    search = "",
    date = null,
    tsn = "",
  } = props;
  const styles = useStyles();

  const { setEditInventoryData } = authActions;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const [pageLoad, setPageLoad] = useState(true);
  // const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState(tsn);
  const [inventoryData, setInventoryData] = useState({});
  // const [datePicker, setDatePicker] = useState(null);
  const [activeIndex, setactiveIndex] = useState(null);
  const [tyreId, setTyreId] = useState("");
  const [conformModel, setConformModel] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [tab, setTab] = useState(0);
  const [visible, setVisible] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);

  const [fleetList, setFleetList] = useState(false);
  const [categoryList, setcategoryList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [compositionList, setCompositionList] = useState(false);
  const [ConditionList, setConditionList] = useState(false);
  const [typeList, setTypeList] = useState(false);

  const [tyreInventoryArr, setTyreInventoryArr] = useState({});
  const [downloadFilter, setDownloadFilter] = useState({});
  const [downLoader, setDownLoader] = useState(false);

  const [modal, openModal] = useState(false);
  const [imgArr, setImgArr] = useState("");

  const categoryListArray = [
    {
      label: "Scrap",
    },
    {
      label: "On Wheel",
    },
    {
      label: "Stock",
    },
    {
      label: "Claim",
    },
    {
      label: "Retreading",
    },
    {
      label: "Casing",
    },
  ];

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const { token, dropDownData, fleetData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    getitemInventoryList(false);
    setactiveIndex(null);
  }, [record, pageNo, filter, selectFleet]);

  useEffect(() => {
    isNull(date) && isEmpty(search) && getitemInventoryList(false);
  }, [date, search]);

  useEffect(() => {
    if (!isEmpty(tyreInventoryArr) && tyreInventoryArr?.pagination?.isMore) {
      getInventoryReport(
        downloadFilter?.type,
        downloadFilter?.fleetIds,
        downloadFilter?.vehicleIds,
        downloadFilter?.fromDate,
        downloadFilter?.toDate,
        downloadFilter?.isAll,
        downloadFilter?.category,
        downloadFilter?.scrap,
        downloadFilter?.claim,
        downloadFilter?.Casing,
        false
      );
    }
  }, [tyreInventoryArr]);

  useState(() => {
    if (!isEmpty(tsn)) {
      handleSearch(tsn);
    }
  }, [tsn]);

  function resetFilterData() {
    handleFilter("");
    handleSearch("");
    handledate(null);
    handlePageNo(1);
    handleSelectFleet("");
  }

  async function deleteTyre() {
    setRemoveBtnLoad(true);

    try {
      const response = await getApiData(
        `${Setting.endpoints.deletetyre}?id=${tyreId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        setConformModel(false);
        getitemInventoryList(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function tyreRemoveREquest() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreremovalrequest}?tyre_id=${tyreId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConformModel(false);
        getitemInventoryList(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function getitemInventoryList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    const date1 = !isNull(date) ? moment(date).format("YYYY-MM-DD") : "";
    let endpoints = `${
      Setting.endpoints.itemInventoryList
    }?page=${pageNo}&per_page=${record}&search=${bool ? "" : search}&filter=${
      filter || ""
    }&date=${date1}&is_download=${v || ""}&fleet_id=${selectFleet || ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadInvetoryData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInventoryData(listObj);
          }
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }
      v && auditSave("Tyre Inventory List");
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
    }
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre Inventory`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function createImageArr(data) {
    let newStr = "";
    const URL1 = data[0] ? data[0] : "-";
    const URL2 = data[1] ? data[1] : "-";
    const URL3 = data[2] ? data[2] : "-";
    const URL4 = data[3] ? data[3] : "-";
    const URL5 = data[4] ? data[4] : "-";
    if (isArray(data) && !isEmpty(data)) {
      newStr = `1) ${URL1}\n2) ${URL2}\n3) ${URL3}\n4) ${URL4}\n5) ${URL5}`;
    }
    return newStr;
  }

  function downloadInvetoryData(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const plyRating =
        !isNull(item?.ply_rating_id) &&
        dropDownData?.ply_rating.find(
          (v) => item?.ply_rating_id.toString() === v?.id.toString()
        );

      const images = createImageArr(item?.tyre_images);

      const newObj = {
        "Sr. No.": index + 1,
        Category: item?.category,
        "Tyre Serial No.": item?.tyre_serial_no,
        "Fleet Name": item?.fleet_name,
        "Vehicle no.": item?.vehicle_no || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Position": item?.tyre_position || "- ",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.tyre_pattern_size || "-",
        "Tyre Fitment Reading (Km)": isEmpty(item?.tyre_fitment_km)
          ? "-"
          : Number(item?.tyre_fitment_km?.toString()?.replaceAll(",", "")),
        "Tyre Mileage (Total in Km)": Number(item?.total_mileage || "-"),
        "Tyre Mileage (Vehicle wise in Km)":
          mileageHistory(item?.vehicle_wise_mileage).toString() || "-",
        "Original NSD (mm)": item?.original_nsd,
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg. NSD (mm)": item?.avg_nsd || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Type": item?.tyre_type || "-",
        "No. of Retread": item?.no_of_retread || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Ply Rating": plyRating?.label || "-",
        "Air Pressure": `${item?.tyre_air_pressure || "-"} psi`,
        "Added Date": moment(item?.capture_date).format("DD-MM-yyyy") || "-",
        "Last Changed Date":
          moment(item?.last_changed_date).format("DD-MM-yyyy") || "-",
        Images: images?.toString() || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Inventory List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Inventory List.xlsx");
  }

  function plyRating(item) {
    let a =
      !isNull(item?.ply_rating_id) &&
      dropDownData?.ply_rating.find(
        (v) => item?.ply_rating_id.toString() === v?.id.toString()
      );
    return a.label;
  }

  function datec(item) {
    const timestamp = item?.capture_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return <Typography>{displayDate || "-"}</Typography>;
  }

  function Ldate(item) {
    const timestamp = item?.last_changed_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return <Typography>{displayDate || "-"}</Typography>;
  }

  function nsd(data) {
    const nsdArr = data?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
      groove2 !== 0 ? groove2.toFixed(2) : " - "
    }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
      groove4 !== 0 ? groove4.toFixed(2) : " - "
    } mm`;
  }

  async function getInventoryReport(
    type,
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    category,
    scrap,
    claim,
    Casing,
    bool
  ) {
    setDownLoader(true);

    let page = 0;
    const cPage =
      tyreInventoryArr &&
      tyreInventoryArr.pagination &&
      tyreInventoryArr.pagination.currentPage
        ? toNumber(tyreInventoryArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("yyyy-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("yyyy-MM-DD") : "";
    const status =
      category === "Scrap"
        ? scrap
        : category === "Claim"
        ? claim
        : category === "Casing"
        ? Casing
        : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.reportinventory
        }?fleet_id=${fleetIds}&category=${category}&from_date=${from_date}&to_date=${
          isEmpty(to_date) ? from_date : to_date
        }&status=${status}&page=${page}&is_all=0`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(tyreInventoryArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        if (!obj?.pagination?.isMore) {
          setDownLoader(false);
          downloadReport(obj?.data);
          auditSave("Inventory report");
          setTyreInventoryArr({});
          setDownloadFilter({});
        } else {
          setTyreInventoryArr(obj);
        }
      } else {
        toast.error(response?.message || "No Data Found");
        setDownLoader(false);
        setDownloadFilter({});
      }
    } catch (error) {
      setDownLoader(false);
      setDownloadFilter({});
      toast.error(error.toString(), { toastId: 17 });
    }
  }

  function downloadReport(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const fDate = isEmpty(item?.fitment_date)
        ? "-"
        : moment(item?.fitment_date).format("DD-MM-yyyy");
      const displayDate = !isNull(item?.added_date)
        ? moment(item?.added_date).format("DD-MM-yyyy")
        : "-";
      const displayDate1 = moment(item?.date).format("DD-MM-yyyy");
      //new change by client
      const newObj = {
        "Sr No.": index + 1,
        Category: item?.category,
        Status:
          item?.category === "Claim"
            ? item?.claim_status === 0
              ? "Pending"
              : item?.claim_status === 1
              ? "Accepted"
              : "Rejected"
            : item?.category === "Casing"
            ? item?.casing_status === 0
              ? "Pending"
              : item?.casing_status === 1
              ? "Accepted"
              : "Rejected"
            : item?.category === "Scrap"
            ? item?.scrap_status === 0
              ? "Unsold"
              : "Sold"
            : "-",
        "Tyre Serial No.": item?.tyre_serial_no,
        "Tyre Brand": item?.tyre_brand,
        "Item Description": item?.item_description,
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        Issue: item?.scrap_issue ? item?.scrap_issue.toString() || "-" : "-",
        "Original NSD (mm)": item?.original_nsd || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Added Date": displayDate || "-",
        "Category Change Date": displayDate1 || "-",
      };
      return newObj;
      //old code
      // const newObj = {
      //   "Sr No.": index + 1,
      //   "Vehicle No.": item?.vehicle_no,
      //   Category: item?.category,
      //   "Axle Type": item?.axle_type,
      //   "Tyre Serial No.": item?.tyre_serial_no,
      //   "Tyre Brand": item?.tyre_brand,
      //   "Item Description": item?.item_description,
      //   "Tyre Position": item?.tyre_position,
      //   "Tyre Composition": item?.tyre_composition || "-",
      //   "Tyre Condition": item?.tyre_condition || "-",
      //   "Air Pressure": `${item?.tyre_air_pressure || 0} psi`,
      //   "Original NSD (mm)": item?.original_nsd || "-",
      //   "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
      //   "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
      //   "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
      //   "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
      //   "Current Avg. NSD (mm)": item?.avg_nsd || "-",
      //   "Ply Rating": item?.ply_rating || "-",
      //   "Tyre Fitment Reading (Km)": isEmpty(item?.tyre_fitment_km)
      //     ? "-"
      //     : Number(item?.tyre_fitment_km?.toString()?.replaceAll(",", "")),
      //   "Fitment Date": fDate,
      //   "Added Date": displayDate || "-",
      //   "Category Change Date": displayDate1 || "-",
      // };

      // const newObj1 = {
      //   "Sr No.": index + 1,
      //   Category: item?.category,
      //   Status:
      //     item?.category === "Claim"
      //       ? item?.claim_status === 0
      //         ? "Pending"
      //         : item?.claim_status === 1
      //         ? "Accepted"
      //         : "Rejected"
      //       : item?.category === "Casing"
      //       ? item?.casing_status === 0
      //         ? "Pending"
      //         : item?.casing_status === 1
      //         ? "Accepted"
      //         : "Rejected"
      //       : "-",
      //   "Tyre Serial No.": item?.tyre_serial_no,
      //   "Tyre Brand": item?.tyre_brand,
      //   "Item Description": item?.item_description,
      //   "Tyre Composition": item?.tyre_composition || "-",
      //   "Tyre Condition": item?.tyre_condition || "-",
      //   "Original NSD (mm)": item?.original_nsd || "-",
      //   "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
      //   "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
      //   "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
      //   "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
      //   "Current Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
      //   "Ply Rating": item?.ply_rating || "-",
      //   "Added Date": displayDate || "-",
      //   "Category Change Date": displayDate1 || "-",
      // };

      // const newObj2 = {
      //   "Sr No.": index + 1,
      //   Category: item?.category,
      //   Status: item?.scrap_status === 0 ? "Unsold" : "Sold",
      //   "Tyre Serial No.": item?.tyre_serial_no,
      //   "Tyre Brand": item?.tyre_brand || "-",
      //   "Item Description": item?.tyre_pattern_size || "-",
      //   "Tyre Composition": item?.tyre_composition || "-",
      //   "Tyre Condition": item?.tyre_condition || "-",
      //   Issue: item?.scrap_issue ? item?.scrap_issue.toString() || "-" : "-",
      //   "Original NSD (mm)": item?.original_nsd || "-",
      //   "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
      //   "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
      //   "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
      //   "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
      //   "Current Avg. NSD": item?.avg_nsd || "-",
      //   "Ply Rating": item?.ply_rating || "-",
      //   "Added Date": displayDate || "-",
      //   "Category Change Date": displayDate1 || "-",
      // };
      // return item?.category === "On Wheel"
      //   ? newObj
      //   : item?.category === "Scrap"
      //   ? newObj2
      //   : newObj1;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inventory Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inventory Report.xlsx");
  }

  function mileageHistory(data) {
    const one = Object.keys(data).map((item) => {
      let a = `${item} => ${data[item]}`;
      return a;
    });
    const arr = [];
    one.map((item1) => {
      arr.push(item1);
    });
    return arr;
  }

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageLoad, pageNo]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);

      const pos = section.style.position;
      const top = section.style.top;
      section.style.position = "relative";
      section.style.top = "-70px";
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      section.style.top = top;
      section.style.position = pos;
    }
  }, [activeIndex]);

  const categories = [
    { name: "Total", color: "#D9F8FF", count: "totalCount" },
    { name: "Casing", color: "#D9FFE4", count: "casingCount" },
    { name: "Claim", color: "#FFD9D9", count: "claimCount" },
    { name: "On Wheel", color: "#D9F8FF", count: "onWheelCount" },
    { name: "Retreading", color: "#D9FFE4", count: "retredingCount" },
    { name: "Scrap", color: "#FFD9D9", count: "scrapCount" },
    { name: "Sold", color: "#D9F8FF", count: "soldCount" },
    { name: "Old Stock", color: "#D9FFE4", count: "oldStockCount" },
    { name: "New Stock", color: "#FFD9D9", count: "newStockCount" },
  ];

  const renderCategory = (category) => (
    <Grid item key={category.name} xs={isTablet ? 3 : 4} sm={2}>
      <Typography
        varient="tabletitle"
        style={{
          backgroundColor: category.color,
          padding: 6,
          borderRadius: 4,
        }}
      >
        {category.name} : {inventoryData?.pagination?.[category.count] || 0}
      </Typography>
    </Grid>
  );

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              xs={12}
              sm={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="tableTitle">
                {tab === 0 ? "Tyre Inventory" : "Master Reports"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              sm={4}
              gap={1.2}
              wrap="nowrap"
              justifyContent={"flex-end"}
            >
              {tab === 0 && (
                <>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleClick("addTyre", {});
                        dispatch(setEditInventoryData({}));
                      }}
                    >
                      Add Tyre
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                      }}
                      disabled={isOnline ? false : true || reportLoader}
                      onClick={() => getitemInventoryList(false, 1)}
                    >
                      {reportLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        <img src={Images.Mdownload} alt="download" />
                      )}
                    </Button>
                  </Grid>
                </>
              )}

              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: color.white,
                    color: "#D8D8D8",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tab === 0 ? true : false}
                    onClick={() => {
                      setTab(0);
                      setAnchorEl(null);
                    }}
                  >
                    Tyre List
                  </ListItemButton>
                  <ListItemButton
                    selected={tab === 1 ? true : false}
                    onClick={() => {
                      setTab(1);
                      setAnchorEl(null);
                    }}
                  >
                    Master Reports
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>

          {tab === 0 && (
            <>
              <Grid container>
                <Grid
                  container
                  marginBottom={1}
                  marginTop={"9px"}
                  alignItems={filter !== "" && "center"}
                  gap={1}
                >
                  {filter === "Stock" ? (
                    <>
                      {["Old Stock", "New Stock"].map((name) =>
                        categories.map((category) =>
                          category.name === name
                            ? renderCategory(category)
                            : null
                        )
                      )}
                    </>
                  ) : filter === "Scrap" ? (
                    <>
                      {["Scrap", "Sold"].map((name) =>
                        categories.map((category) =>
                          category.name === name
                            ? renderCategory(category)
                            : null
                        )
                      )}
                    </>
                  ) : filter !== "Casing" &&
                    filter !== "Claim" &&
                    filter !== "On Wheel" &&
                    filter !== "Retreading" &&
                    filter !== "Scrap" &&
                    filter !== "Stock" ? (
                    <Grid container gap={1}>
                      {categories?.map(renderCategory)}
                    </Grid>
                  ) : (
                    categories.map(
                      (category) =>
                        filter === category.name && renderCategory(category)
                    )
                  )}
                </Grid>
                {/* <Grid item container gap={1}>
                  {filter !== "Casing" &&
                  filter !== "Claim" &&
                  filter !== "On Wheel" &&
                  filter !== "Retreading" &&
                  filter !== "Scrap" &&
                  filter !== "Stock" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#D9F8FF",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        Total : {inventoryData?.pagination?.totalCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                  {filter !== "Casing" &&
                  filter !== "Claim" &&
                  filter !== "On Wheel" &&
                  filter !== "Retreading" &&
                  filter !== "Scrap" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#D9FFE4",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        Stock : {inventoryData?.pagination?.stockCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                  {filter !== "Casing" &&
                  filter !== "Claim" &&
                  filter !== "Retreading" &&
                  filter !== "Scrap" &&
                  filter !== "Stock" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#FFD9D9",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        On Wheel :{" "}
                        {inventoryData?.pagination?.onWheelCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                  {filter !== "Claim" &&
                  filter !== "On Wheel" &&
                  filter !== "Retreading" &&
                  filter !== "Scrap" &&
                  filter !== "Stock" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#D9F8FF",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        Casing : {inventoryData?.pagination?.casingCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item container gap={1}>
                  {filter !== "Casing" &&
                  filter !== "On Wheel" &&
                  filter !== "Retreading" &&
                  filter !== "Stock" &&
                  filter !== "Scrap" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#D9FFE4",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        Claim : {inventoryData?.pagination?.claimCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                  {filter !== "Casing" &&
                  filter !== "Claim" &&
                  filter !== "On Wheel" &&
                  filter !== "Scrap" &&
                  filter !== "Stock" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#FFD9D9",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        Retreading :{" "}
                        {inventoryData?.pagination?.retredingCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                  {filter !== "Casing" &&
                  filter !== "Claim" &&
                  filter !== "On Wheel" &&
                  filter !== "Retreading" &&
                  filter !== "Stock" ? (
                    <Grid item>
                      <Typography
                        varient="tabletitle"
                        style={{
                          backgroundColor: "#D9F8FF",
                          padding: 6,
                          marginTop: 14,
                          borderRadius: 4,
                        }}
                      >
                        Scrap : {inventoryData?.pagination?.scrapCount || 0}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid> */}
              </Grid>
              <Grid
                container
                gap={1}
                style={{ marginTop: 10, gap: 10 }}
                wrap="nowrap"
              >
                <Grid item sm={2.5}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={selectFleet}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleSelectFleet(v.target.value);
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={selectFleet === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value="" disabled hidden selected>
                      Fleet
                    </MenuItem>
                    {fleetData.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: "flex" }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item sm={2.5}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        setcategoryList(false);
                        setbrandList(false);
                        setFleetList(false);
                        setTypeList(false);
                        setCompositionList(false);
                        setConditionList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value="" disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setcategoryList(!categoryList);
                        setbrandList(false);
                        setFleetList(false);
                        setCompositionList(false);
                        setConditionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Category</Typography>
                      {categoryList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {categoryListArray.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: categoryList ? "flex" : "none" }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        setcategoryList(false);
                        setFleetList(false);
                        setCompositionList(false);
                        setConditionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: brandList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setTypeList(!typeList);
                        setFleetList(false);
                        setbrandList(false);
                        setcategoryList(false);
                        setCompositionList(false);
                        setConditionList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: typeList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}

                    <ListItemButton
                      onClick={() => {
                        setCompositionList(!compositionList);
                        setbrandList(false);
                        setFleetList(false);
                        setcategoryList(false);
                        setConditionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Composition</Typography>
                      {compositionList ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItemButton>
                    {dropDownData.tyre_composition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: compositionList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setConditionList(!ConditionList);
                        setbrandList(false);
                        setFleetList(false);
                        setcategoryList(false);
                        setCompositionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Condition</Typography>
                      {ConditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_condition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: ConditionList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid item style={{ marginLeft: "auto" }}>
                  <Select
                    value={record}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleRecord(v.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container gap={1} style={{ marginTop: 10 }} wrap="nowrap">
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      views={["year", "month", "day"]}
                      disableFuture
                      value={date}
                      onChange={(newValue) => {
                        handledate(newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.date }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={7}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={search}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getitemInventoryList(true);
                      handleSearch(v?.target?.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        if (!isEmpty(search) || !isNull(date)) {
                          handlePageNo(1);
                          getitemInventoryList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Tyre Serial No. and Item Description"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item>
                  <Button
                    varient="contained"
                    onClick={() => {
                      if (!isEmpty(search) || !isNull(date)) {
                        getitemInventoryList(false);
                      }
                    }}
                    style={{
                      backgroundColor: color.secondary,
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={resetFilterData}
                    disabled={
                      filter === "" &&
                      !selectFleet &&
                      isEmpty(search) &&
                      isNull(date)
                    }
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(inventoryData?.data) &&
                !isEmpty(inventoryData?.data) ? (
                <>
                  {inventoryData?.data?.map((item, index) => {
                    return (
                      <Grid
                        container
                        style={{
                          border: `1px solid ${color.bordercolor}`,
                          padding: 15,
                          marginTop: 10,
                          overflow: "auto",
                          boxShadow: color.shadow,
                        }}
                        alignItems="center"
                      >
                        <Grid item container>
                          <Table className={styles.customtable}>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Category</TableCell>
                                <TableCell variant="head">
                                  Tyre Serial No.
                                </TableCell>
                                <TableCell variant="head">Fleet Name</TableCell>
                                <TableCell variant="head">
                                  Vehicle No.
                                </TableCell>
                                <TableCell variant="head">
                                  Vehicle Brand
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>{item?.category || "-"}</TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell>{item?.fleet_name || "-"}</TableCell>
                              <TableCell>{item?.vehicle_no || "-"}</TableCell>
                              <TableCell>
                                {item?.vehicle_brand || "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Divider
                                  className={styles.divide}
                                  variant="fullWidth"
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Vehicle Model
                                </TableCell>
                                <TableCell variant="head">
                                  Vehicle Type
                                </TableCell>
                                <TableCell variant="head">Axle Type</TableCell>
                                <TableCell variant="head">
                                  Tyre Position
                                </TableCell>
                                <TableCell variant="head">Tyre Brand</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                {item?.vehicle_model || "-"}
                              </TableCell>
                              <TableCell>{item?.vehicle_type || "-"}</TableCell>
                              <TableCell>{item?.axle_type || "-"}</TableCell>
                              <TableCell>
                                <Typography style={{ whiteSpace: "pre-wrap" }}>
                                  {item?.tyre_position || "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography style={{ whiteSpace: "pre-wrap" }}>
                                  {item?.tyre_brand}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Divider
                                  className={styles.divide}
                                  variant="fullWidth"
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Item Description
                                </TableCell>
                                <TableCell variant="head">
                                  Tyre Fitment Reading (Km)
                                </TableCell>
                                <TableCell variant="head">
                                  Tyre mileage (Total in Km)
                                </TableCell>
                                <TableCell variant="head">
                                  Tyre mileage (Vehicle wise in Km)
                                </TableCell>
                                <TableCell variant="head">
                                  Original NSD (mm)
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography>
                                  {item?.tyre_pattern_size || "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="span">
                                  {item?.tyre_fitment_km || "-"} Km
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {item?.total_mileage || "-"} Km
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {mileageHistory(
                                    item?.vehicle_wise_mileage
                                  ).toString() || "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {`${item?.original_nsd} mm` || "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Divider
                                  className={styles.divide}
                                  variant="fullWidth"
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Current NSD (mm)
                                </TableCell>
                                <TableCell variant="head">
                                  Current Avg. NSD (mm)
                                </TableCell>
                                <TableCell variant="head">
                                  Tyre Composition
                                </TableCell>
                                <TableCell variant="head">Tyre Type</TableCell>
                                <TableCell variant="head">
                                  No. of Retread
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>{nsd(item) || "-"}</TableCell>
                              <TableCell>
                                {`${item?.avg_nsd} mm` || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.tyre_composition || "-"}
                              </TableCell>
                              <TableCell>{item?.tyre_type || "-"}</TableCell>
                              <TableCell>
                                <Typography>
                                  {item?.no_of_retread || 0}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Divider
                                  className={styles.divide}
                                  variant="fullWidth"
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Tyre Condition
                                </TableCell>
                                <TableCell variant="head">Ply Rating</TableCell>
                                <TableCell variant="head">
                                  Air Pressure
                                </TableCell>
                                <TableCell variant="head">Added Date</TableCell>
                                <TableCell variant="head">
                                  Last Changed Date
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                <div
                                  style={{
                                    backgroundColor:
                                      item?.tyre_condition === "Average"
                                        ? "#FFFDD9"
                                        : item?.tyre_condition === "Good"
                                        ? "#D9FFE4"
                                        : item?.tyre_condition === "Bad"
                                        ? "#FFD9D9"
                                        : "",
                                    padding: 8,
                                    borderRadius: 4,
                                  }}
                                >
                                  <Typography>
                                    {item?.tyre_condition || "-"}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell>
                                <Typography variant="span">
                                  {plyRating(item)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {item?.tyre_air_pressure || 0} psi
                                </Typography>
                              </TableCell>
                              <TableCell>{datec(item) || "-"}</TableCell>
                              <TableCell>{Ldate(item) || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Divider
                                  className={styles.divide}
                                  variant="fullWidth"
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Images</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  onClick={() => {
                                    if (
                                      !isEmpty(item?.tyre_images) &&
                                      isArray(item?.tyre_images)
                                    ) {
                                      setImgArr(item?.tyre_images);
                                      openModal(true);
                                    } else {
                                      return null;
                                    }
                                  }}
                                  style={{
                                    color:
                                      !isEmpty(item?.tyre_images) &&
                                      isArray(item?.tyre_images)
                                        ? color.primary
                                        : color.disable,
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                >
                                  View
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Divider
                                  className={styles.divide}
                                  variant="fullWidth"
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell colSpan={4}>
                                <Button
                                  varient="contained"
                                  style={{
                                    backgroundColor: color.primary,
                                    marginLeft: 15,
                                  }}
                                  onClick={() => {
                                    handleClick("addTyre", item);
                                    dispatch(setEditInventoryData(item));
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  disabled={
                                    item?.category !== "Stock" ? true : false
                                  }
                                  onClick={() => {
                                    if (item?.category === "Stock") {
                                      setTyreId(item?.id);
                                      setConformModel(true);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      item?.category === "Stock"
                                        ? "red"
                                        : color.disable,
                                    marginLeft: "auto",
                                    display: "flex",
                                    marginRight: 15,
                                  }}
                                >
                                  <Delete />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Box
                    style={{
                      margin: 15,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(inventoryData?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={2}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </Box>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </>
          )}

          {tab === 1 && (
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ margin: "20px 0", padding: "0 10px" }}
            >
              <Typography>Tyre Inventory Report</Typography>
              <Button
                disabled={isOnline ? false : true}
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                onClick={() => setVisible(true)}
              >
                {downLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  "Download"
                )}
              </Button>
            </Grid>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              container
              display="flex"
              alignItems="center"
              backgroundColor={color.secondary}
              padding={"10px"}
              color={color.white}
              borderRadius={1}
            >
              <Grid item>
                <Typography variant="tableTitle">Tyre Inventory</Typography>
              </Grid>

              <Grid item marginLeft={"auto"}>
                {tab === 0 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleClick("addTyre", {});
                      dispatch(setEditInventoryData({}));
                    }}
                  >
                    Add Tyre
                  </Button>
                )}

                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#BBBBBB",
                    minWidth: "unset",
                    color: "#000",
                    marginLeft: 10,
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </Button>

                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tab === 0 ? true : false}
                    onClick={() => {
                      setTab(0);
                      setAnchorEl(null);
                    }}
                  >
                    Tyre List
                  </ListItemButton>
                  <ListItemButton
                    selected={tab === 1 ? true : false}
                    onClick={() => {
                      setTab(1);
                      setAnchorEl(null);
                    }}
                  >
                    Master Reports
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>

          {tab === 0 && (
            <>
              <Grid container margin={"10px 1px"}>
                <Typography variant="tableTitle">Tyre List</Typography>
              </Grid>
              {filter !== "Casing" &&
                filter !== "Claim" &&
                filter !== "On Wheel" &&
                filter !== "Retreading" &&
                filter !== "Scrap" &&
                filter !== "Stock" && (
                  <Grid container gap={1}>
                    <Grid item container gap={2} wrap="nowrap">
                      {categories.slice(0, 3).map(renderCategory)}
                    </Grid>
                    <Grid item container gap={2} wrap="nowrap">
                      {categories.slice(3, 6).map(renderCategory)}
                    </Grid>
                    <Grid item container gap={2} wrap="nowrap">
                      {categories.slice(6, 9).map(renderCategory)}
                    </Grid>
                  </Grid>
                )}
              <Grid
                container
                marginBottom={1}
                marginTop={"9px"}
                alignItems={filter !== "" && "center"}
                gap={1}
              >
                {filter === "Stock" ? (
                  <>
                    {["Old Stock", "New Stock"].map((name) =>
                      categories.map((category) =>
                        category.name === name ? renderCategory(category) : null
                      )
                    )}
                  </>
                ) : filter === "Scrap" ? (
                  <>
                    {["Scrap", "Sold"].map((name) =>
                      categories.map((category) =>
                        category.name === name ? renderCategory(category) : null
                      )
                    )}
                  </>
                ) : (
                  categories.map((category) =>
                    filter === category.name ? renderCategory(category) : null
                  )
                )}
              </Grid>
              <Grid
                container
                item
                gap={1}
                wrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      views={["year", "month", "day"]}
                      disableFuture
                      value={date}
                      onChange={(newValue) => {
                        handledate(newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    value={record}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleRecord(v.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                    }}
                    disabled={!isOnline}
                    onClick={() => getitemInventoryList(false, 1)}
                  >
                    {reportLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      <img src={Images.Mdownload} alt="download" />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid item container gap={1} wrap="nowrap">
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={selectFleet}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleSelectFleet(v.target.value);
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={selectFleet === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value="" disabled hidden selected>
                      Fleet
                    </MenuItem>
                    {fleetData.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: "flex" }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        setcategoryList(false);
                        setbrandList(false);
                        setFleetList(false);
                        setTypeList(false);
                        setCompositionList(false);
                        setConditionList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value="" disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setcategoryList(!categoryList);
                        setbrandList(false);
                        setFleetList(false);
                        setCompositionList(false);
                        setConditionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Category</Typography>
                      {categoryList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {categoryListArray.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: categoryList ? "flex" : "none" }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        setcategoryList(false);
                        setFleetList(false);
                        setCompositionList(false);
                        setConditionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: brandList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setTypeList(!typeList);
                        setFleetList(false);
                        setbrandList(false);
                        setcategoryList(false);
                        setCompositionList(false);
                        setConditionList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: typeList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setCompositionList(!compositionList);
                        setbrandList(false);
                        setFleetList(false);
                        setcategoryList(false);
                        setConditionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Composition</Typography>
                      {compositionList ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItemButton>
                    {dropDownData.tyre_composition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: compositionList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}

                    <ListItemButton
                      onClick={() => {
                        setConditionList(!ConditionList);
                        setbrandList(false);
                        setFleetList(false);
                        setcategoryList(false);
                        setCompositionList(false);
                        setTypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Condition</Typography>
                      {ConditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_condition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: ConditionList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>

              <div style={{ marginTop: 10 }}>
                <Grid
                  container
                  xs={12}
                  gap={1}
                  wrap="nowrap"
                  alignItems="center"
                >
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      placeholder="Search"
                      className={styles.inputFieldStyle}
                      value={search}
                      onChange={(v) => {
                        isEmpty(v.target.value) && getitemInventoryList(true);
                        handleSearch(v?.target?.value);
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          if (!isEmpty(search) || !isNull(date)) {
                            handlePageNo(1);
                            getitemInventoryList(false);
                          }
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ lineHeight: 0 }}>
                              <Search style={{ fontSize: 20 }} />
                            </div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ lineHeight: 0, cursor: "pointer" }}>
                              <Tooltip
                                title="Search by Tyre Serial No. and Item Description"
                                placement="bottom"
                                arrow
                              >
                                <InfoIcon style={{ fontSize: 20 }} />
                              </Tooltip>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      varient="contained"
                      onClick={() => {
                        if (!isEmpty(search) || !isNull(date)) {
                          getitemInventoryList(false);
                        }
                      }}
                      style={{
                        backgroundColor: color.secondary,
                        width: "100%",
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={resetFilterData}
                      disabled={
                        !search && !selectFleet && !filter && isNull(date)
                      }
                    >
                      <ResetIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>

              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(inventoryData?.data) &&
                !isEmpty(inventoryData?.data) ? (
                <>
                  {inventoryData?.data?.map((item, index) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Grid
                          id={`jump_to_me${index}`}
                          container
                          style={{
                            marginTop: 20,
                            border: `1px solid ${color.bordercolor}`,
                            alignItems: "center",
                            overflow: "hidden",
                            boxShadow: color.shadow,
                          }}
                        >
                          {activeIndex !== index ? (
                            <>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Category
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Serial No.
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>{item?.category}</Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  container
                                  wrap="nowrap"
                                  alignItems={"center"}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Fleet Name
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle No.
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>{item?.fleet_name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_no || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Category
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Serial No.
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>
                                    {item?.category || "-"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  container
                                  wrap="nowrap"
                                  alignItems={"center"}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Fleet Name
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle No.
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>{item?.fleet_name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_no || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle Brand
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle Model
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_brand || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_model || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle Type
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Axle Type
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_type || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.axle_type || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Position
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Brand
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_position || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_brand || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Item Description
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Fitment Reading (Km)
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_pattern_size || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_fitment_km || "-"} Km
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Mileage (Total in Km)
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Mileage (Vehicle wise in Km)
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography variant="span">
                                    {item?.total_mileage || "-"} Km
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {mileageHistory(
                                      item?.vehicle_wise_mileage
                                    ).toString() || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Original NSD (mm)
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Current NSD (mm)
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {`${item?.original_nsd} mm` || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>{nsd(item) || "-"}</Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Current Avg. NSD (mm)
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Composition
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {`${item?.avg_nsd} mm` || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_composition || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Type
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    No. of Retread
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>
                                    {item?.tyre_type || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.no_of_retread || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Condition
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Ply Rating
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  container
                                  wrap="nowrap"
                                  alignItems={"center"}
                                >
                                  <Typography
                                    variant="span"
                                    style={{
                                      backgroundColor:
                                        item?.tyre_condition === "Average"
                                          ? "#FFFDD9"
                                          : item?.tyre_condition === "Good"
                                          ? "#D9FFE4"
                                          : item?.tyre_condition === "Bad"
                                          ? "#FFD9D9"
                                          : "",
                                      padding: 4,
                                      borderRadius: 4,
                                    }}
                                  >
                                    {item?.tyre_condition || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {plyRating(item) || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Air Pressure
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Added Date
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>
                                    {item?.tyre_air_pressure || 0} psi
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>{datec(item) || "-"}</Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Last Changed Date
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Images
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Typography>{Ldate(item) || "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    onClick={() => {
                                      if (
                                        !isEmpty(item?.tyre_images) &&
                                        isArray(item?.tyre_images)
                                      ) {
                                        setImgArr(item?.tyre_images);
                                        openModal(true);
                                      } else {
                                        return null;
                                      }
                                    }}
                                    style={{
                                      color:
                                        !isEmpty(item?.tyre_images) &&
                                        isArray(item?.tyre_images)
                                          ? color.primary
                                          : color.disable,
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                  >
                                    View
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                                gap={2}
                              >
                                <Grid item xs={6}>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      handleClick("addTyre", item);
                                      dispatch(setEditInventoryData(item));
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={6}
                                  justifyContent={"flex-end"}
                                >
                                  <Button
                                    style={{
                                      justifyContent: "flex-end",
                                      backgroundColor:
                                        item?.category !== "Stock"
                                          ? color.disable
                                          : "red",
                                    }}
                                    disabled={
                                      item?.category !== "Stock" ? true : false
                                    }
                                    onClick={() => {
                                      if (item?.category === "Stock") {
                                        setTyreId(item?.id);
                                        setConformModel(true);
                                      }
                                    }}
                                  >
                                    <Delete />
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <div
                          onClick={(v) => {
                            if (activeIndex === index) {
                              setactiveIndex(null);
                            } else {
                              setactiveIndex(index);
                            }
                          }}
                          style={{
                            position: "absolute",
                            bottom: -18,
                            left: "45%",
                          }}
                        >
                          {activeIndex === index ? (
                            <div style={{ transform: "rotate(180deg)" }}>
                              <TableToggle />
                            </div>
                          ) : (
                            <TableToggle />
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <Box
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(inventoryData?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </Box>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </>
          )}

          {tab === 1 && (
            <>
              <Grid container margin={"10px 1px"}>
                <Typography variant="tableTitle">Master Reports</Typography>
              </Grid>
              <div style={{ padding: isMobile ? 0 : "0px 10px 0px 10px" }}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Typography>Tyre Inventory Report</Typography>
                  <Button
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginRight: isMobile ? 0 : 20,
                    }}
                    onClick={() => setVisible(true)}
                  >
                    {downLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </Grid>
              </div>
            </>
          )}
        </div>
      )}
      <ConfirmDialog
        btnLoad={removeBtnLoad}
        title="Are you sure you want to Remove Tyre?"
        visible={conformModel}
        handleModal={(bool) => {
          if (bool) {
            if (userdata?.role === "superadmin" || userdata?.role === "admin") {
              deleteTyre();
            } else {
              tyreRemoveREquest();
            }
          } else {
            setConformModel(false);
          }
        }}
      />
      <MDateRange
        category
        disableVehicle
        visible={visible}
        handleModal={(
          type,
          fleetIds,
          vehicleIds,
          fromDate,
          toDate,
          isAll,
          category,
          scrap,
          claim,
          Casing
        ) => {
          if (type === "download") {
            getInventoryReport(
              type,
              fleetIds,
              vehicleIds,
              fromDate,
              toDate,
              isAll,
              category,
              scrap,
              claim,
              Casing,
              true
            );
            setDownloadFilter({
              type,
              fleetIds,
              vehicleIds,
              fromDate,
              toDate,
              isAll,
              category,
              scrap,
              claim,
              Casing,
            });
          }
          setVisible(false);
        }}
      />
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <div
          style={{
            maxWidth: "80vw",
            maxHeight: "80vh",
            backgroundColor: "white",
            padding: 10,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={1}
            style={{ overflowY: "scroll", maxHeight: "60vh" }}
          >
            {isArray(imgArr) &&
              !isEmpty(imgArr) &&
              imgArr.map((item, index) => {
                return (
                  <ImageListItem key={index}>
                    <img
                      style={{
                        minWidth: "100%",
                        minHeight: "100%",
                      }}
                      src={`${item}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Modal>
    </>
  );
}
