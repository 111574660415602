import React, { useEffect, useState, useRef } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemButton,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { ArrowBackOutlined, MoreVert, TuneOutlined } from "@mui/icons-material";
import TabInspectionHistory from "../../../Components/TabInspection/TabInspectionHistory/index.js";
import TabAddInspection from "../../../Components/TabInspection/TabAddInspection/index.js";
import TabInspectionDetails from "../../../Components/TabInspection/TabInspectionDetails/index.js";
import ModificationRequest from "../../../Components/TabInspection/TabModificationRequest/index.js";
import TabInspectionIssueDetails from "../../../Components/TabInspection/TabInspectionIssueDetails/index.js";
import TabVehicleInspection from "../../../Components/TabInspection/TabVehicleInspection/index.js";
import Images from "../../../Config/Images.js";
import TabResolveIssue from "../../../Components/TabFleet/TabResolveIssue/index.js";
import { isMobile, isTablet } from "react-device-detect";
import { isNull } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import MasterReport from "../../../Components/InspectionManagement/Reports/Reports.js";
import { useSelector } from "react-redux";
import TabUnresolvedIssue from "../../../Components/TabFleet/TabUnresolvedIssue/index.js";
import RemoveTyre from "../../../Components/TyreHistory/RemoveTyre/index.js";
import AddTyre from "../../../Components/TyreHistory/AddTyre/index.js";
import AddInspectionForm from "../../../Components/InspectionManagement/AddInspectionForm/index.js";
import TabRemovalTyreRequest from "../../../Components/TabInspection/TabRemovalTyreRequest/index.js";

export default function TabInspection() {
  const styles = useStyles();
  const location = useLocation();
  const { isOnline } = useSelector((state) => state.auth);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const navigate = useNavigate();

  const [changeTab, setChangeTab] = useState("");
  const [inspectionData, setInspactionData] = useState({});
  const [editRowData, setEditRowData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [scroll, setScroll] = useState("");
  const [from, setFrom] = useState("");

  const [isDirect, setisDirect] = useState(false);
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");
  const [removalData, setRemovalData] = useState({});

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const [tyreData, setTyreData] = useState({});

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const inspectionHistory = useRef();
  const modificationRequest = useRef();
  const removalTyreRequest = useRef();

  const fromDashboard =
    !isNull(location?.state) && location?.state?.set === "dashboardDetails";
  const dashboard = location?.state?.set === "inspectionHistory";
  const dashboard2addInspection = location?.state?.set === "addInspection";

  const [pageNoIH, setPageNoIH] = useState(1);
  const [recordIH, setRecordIH] = useState(10);
  const [searchFilterIH, setSearchFilterIH] = useState("");
  const [datePickerIH, setDatePickerIH] = useState(null);

  const [pageNoMR, setPageNoMR] = useState(1);
  const [recordMR, setRecordMR] = useState(10);
  const [filter, setFilter] = useState("");
  const [searchFilterMR, setSearchFilterMR] = useState("");
  const [datePickerMR, setDatePickerMR] = useState(null);

  const [pageNoID, setPageNoID] = useState(1);
  const [recordID, setRecordID] = useState(10);
  const [filterID, setFilterID] = useState("");

  const [pageNoUI, setPageNoUI] = useState(1);
  const [recordUI, setRecordUI] = useState(10);
  const [filterUI, setFilterUI] = useState("");

  const [pageNoPT, setPageNoPT] = useState(1);
  const [recordPT, setRecordPT] = useState(10);

  const [pageNoPM, setPageNoPM] = useState(1);
  const [recordPM, setRecordPM] = useState(10);

  const [pageNoRT, setPageNoRT] = useState(1);
  const [recordRT, setRecordRT] = useState(10);

  const [pageNoRM, setPageNoRM] = useState(1);
  const [recordRM, setRecordRM] = useState(10);

  const [pageNoTR, setPageNoTR] = useState(1);
  const [recordTR, setRecordTR] = useState(10);
  const [filterTR, setFilterTR] = useState("");
  const [searchFilterTR, setSearchFilterTR] = useState("");

  const [PtabValue, setPTabValue] = useState(0);
  const [RtabValue, setRTabValue] = useState(0);
  const [reportLoader, setReportLoader] = useState(false);

  useEffect(() => {
    if (fromDashboard) {
      setChangeTab("inspectionHistory");
      setInspactionData(location?.state);
    }
  }, []);

  useEffect(() => {
    if (dashboard2addInspection) {
      let subData = {
        fleet: {
          label: location?.state?.data?.fleet_name,
          id: location?.state?.data?.fleet_id,
        },
        vehicle: {
          label: location?.state?.data?.vehicle_no,
          id: location?.state?.data?.vehicle_id,
        },
      };
      setRemovalData(subData);
      setFrom("dashboard");
      setChangeTab("addInspection");
    }
  }, []);

  useEffect(() => {
    if (location?.state?.from === "notificationU") {
      setTabValue(1);
    } else if (location?.state?.from === "notificationR") {
      setTabValue(2);
    }
  }, []);

  useEffect(() => {
    document.title = Setting.page_name.Inspection;
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  return (
    <div className={styles.card}>
      {isTablet
        ? ""
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}
      {changeTab === "addInspection" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                dashboard2addInspection
                  ? navigate("/dashboard")
                  : setChangeTab("");
                setRemovalData({});
                setFrom("");
                setScroll("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {(changeTab === "removeTyre" || changeTab === "addInspectionForm") && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab(
                  from === "vehicleInspection"
                    ? "vehicleInspection"
                    : "addInspection"
                );
                setFrom("inside");
                setScroll("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "vehicleInspectionChanges" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "vehicleInspection" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setScroll("");
                setChangeTab("inspectionHistory");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "resolveIssue" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab(
                  from === "UnresolvedIssue"
                    ? "UnresolvedIssue"
                    : from === "issueDetails"
                    ? "inspectionIssue"
                    : "vehicleInspection"
                );
                setFrom("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "UnresolvedIssue" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}

      {changeTab === "inspectionHistory" ? (
        <TabInspectionDetails
          pageNo={pageNoID}
          record={recordID}
          filter={filterID}
          handlePageNo={(page) => {
            setPageNoID(page);
          }}
          handleRecord={(rec) => {
            setRecordID(rec);
          }}
          handleFilter={(flt) => {
            setFilterID(flt);
          }}
          handleClick={(type, data) => {
            if (type === "vehicleInspection") {
              setChangeTab("vehicleInspection");
              setInspactionData(data);
              setFrom("");
            } else if (type === "cancel") {
              setChangeTab("");
            }
          }}
          editData={inspectionData}
          inspectionData={inspectionData}
        />
      ) : changeTab === "addInspection" ? (
        <TabAddInspection
          removalData={removalData}
          from={from}
          scroll={scroll}
          handleClick={(type, data, subData) => {
            if (type === "cancel") {
              setChangeTab("");
              setFrom("");
              setScroll("");
            } else if (type === "empty") {
              setFrom("");
              setRemovalData({});
            } else if (type === "removeTyre") {
              setChangeTab("removeTyre");
              setFrom("addInspection");
              setTyreData(data);
              setRemovalData(subData);
            } else if (type === "addInspectionForm") {
              setChangeTab("addInspectionForm");
              setTyreData(data);
              setRemovalData(subData);
            }
          }}
        />
      ) : changeTab === "addInspectionForm" ? (
        <AddInspectionForm
          removalData={removalData}
          tyreData={tyreData}
          from={from}
          handleClick={(type, data, frm) => {
            setFrom("inside");
            if (type === "cancel") {
              frm === "vehicleInspection"
                ? setTimeout(() => {
                    setChangeTab("vehicleInspection");
                  }, 200)
                : setChangeTab("addInspection");
              setTyreData({});
              setEditRowData({});
              setScroll(data);
            } else if (type === "success") {
              setScroll(data);
              setEditRowData(data);
              frm === "vehicleInspection"
                ? setTimeout(() => {
                    setChangeTab("vehicleInspection");
                  }, 200)
                : setChangeTab("addInspection");
            }
            setFrom("inside");
          }}
        />
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          from={from}
          tyreData={tyreData}
          vehicleList={removalData?.vehicleList}
          fitmentReading={removalData?.fitment_reading}
          captureDate={removalData?.captureDate}
          vehicleId={removalData?.vehicle?.id}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("addInspection");
              setTyreData({});
              setScroll("");
            } else if (type === "success") {
              if (data === "Scrap" || data === "Stock") {
                setChangeTab("addTyre");
              } else {
                setChangeTab("addInspection");
              }
            }
            setFrom("inside");
          }}
        />
      ) : changeTab === "addTyre" ? (
        <AddTyre
          from={from}
          tyreData={tyreData}
          vehicleId={removalData?.vehicle?.id}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("addInspection");
              setTyreData({});
              setScroll("");
            } else if (type === "success") {
              setChangeTab("addInspection");
            }
            setFrom("inside");
          }}
        />
      ) : changeTab === "vehicleInspection" ||
        changeTab === "vehicleInspectionChanges" ? (
        <TabVehicleInspection
          scroll={scroll}
          handleClick={(type, data, issType, iss) => {
            if (type === "resolveIssue") {
              setChangeTab("resolveIssue");
              setTyreData(data);
              setisDirect(false);
              setIssueType(issType);
              setIssue(iss);
            } else if (type === "inspectionHistory") {
              setChangeTab("inspectionHistory");
            } else if (type === "cancel") {
              setChangeTab("");
              setFrom("");
              setTyreData({});
              setScroll("");
            } else if (type === "addInspectionForm") {
              setChangeTab("addInspectionForm");
              setTyreData(data);
              setFrom("vehicleInspection");
            }
          }}
          from={changeTab === "vehicleInspectionChanges" ? "modification" : ""}
          fromInside={from === "inside"}
          editData={inspectionData}
          editRowData={editRowData}
          inspectionData={inspectionData}
        />
      ) : changeTab === "resolveIssue" ? (
        <TabResolveIssue
          handleClick={(type, data) => {
            if (type === "cancel") {
              from === "UnresolvedIssue"
                ? setChangeTab("UnresolvedIssue")
                : from === "issueDetails"
                ? setChangeTab("inspectionIssue")
                : isDirect
                ? setChangeTab("UnresolvedIssue")
                : setChangeTab("vehicleInspection");
              setFrom("");
            }
          }}
          editData={inspectionData}
          fleetData={inspectionData}
          vehicleDetails={tyreData}
          from={from}
          issueType={issueType}
          issue={issue}
        />
      ) : changeTab === "inspectionIssue" ? (
        <TabInspectionIssueDetails
          PtabValue={PtabValue}
          tabValue={RtabValue}
          handlePTabValue={(tab) => {
            setPTabValue(tab);
          }}
          handleRTabValue={(tab) => {
            setRTabValue(tab);
          }}
          pageNoRT={pageNoRT}
          pageNoRM={pageNoRM}
          recordRT={recordRT}
          recordRM={recordRM}
          handlePageNoRT={(page) => {
            setPageNoRT(page);
          }}
          handleRecordRT={(rec) => {
            setRecordRT(rec);
          }}
          handlePageNoRM={(page) => {
            setPageNoRM(page);
          }}
          handleRecordRM={(rec) => {
            setRecordRM(rec);
          }}
          pageNoPT={pageNoPT}
          pageNoPM={pageNoPM}
          recordPT={recordPT}
          recordPM={recordPM}
          handlePageNoPT={(page) => {
            setPageNoPT(page);
          }}
          handleRecordPT={(rec) => {
            setRecordPT(rec);
          }}
          handlePageNoPM={(page) => {
            setPageNoPM(page);
          }}
          handleRecordPM={(rec) => {
            setRecordPM(rec);
          }}
          handleClick={(type, data, issType, iss) => {
            if (type === "cancel") {
              setChangeTab("");
            } else if (type === "resolveIssue") {
              setFrom("issueDetails");
              setTyreData(data);
              setIssueType(issType);
              setIssue(iss);
              setChangeTab("resolveIssue");
            }
          }}
          inspectionData={inspectionData}
          scroll={scroll}
        />
      ) : changeTab === "UnresolvedIssue" ? (
        <TabUnresolvedIssue
          from="Inspection"
          vehicleData={inspectionData}
          pageNo={pageNoUI}
          record={recordUI}
          filter={filterUI}
          handlePageNo={(page) => {
            setPageNoUI(page);
          }}
          handleRecord={(rec) => {
            setRecordUI(rec);
          }}
          handleFilter={(flt) => {
            setFilterUI(flt);
          }}
          handleClick={(type, data, issType, iss, rec, page) => {
            if (type === "ResolveIssue") {
              setChangeTab("resolveIssue");
              setTyreData(data);
              setFrom("UnresolvedIssue");
              setIssueType(issType);
              setIssue(iss);
            }
          }}
        />
      ) : (
        <>
          {isTablet ? (
            <div className={styles.container}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                id="jump_to_me"
              >
                <Grid item sm={6}>
                  <Typography variant="tableTitle">
                    {
                      // tabValue === 1
                      //   ? "Issue Resolution History"
                      //   :
                      tabValue === 1
                        ? "Modification Requests"
                        : tabValue === 2
                        ? "Tyre Removal Requests"
                        : tabValue === 3
                        ? "Master Reports"
                        : "Inspection Management"
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {tabValue !== 3 && (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setChangeTab("addInspection");
                        }}
                      >
                        Add Inspection
                      </Button>

                      <Button
                        variant="contained"
                        style={{
                          marginLeft: 10,
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                        }}
                        onClick={() => {
                          if (tabValue === 0) {
                            inspectionHistory?.current?.downloadFleetData();
                          }

                          if (tabValue === 1) {
                            modificationRequest?.current?.downloadModificationData();
                          }
                          if (tabValue === 2) {
                            removalTyreRequest?.current?.downloadRemovalData();
                          }
                        }}
                        disabled={isOnline ? false : true}
                      >
                        {reportLoader ? (
                          <CircularProgress
                            style={{ color: color.white }}
                            size={24}
                          />
                        ) : (
                          <img src={Images.Mdownload} alt="download" />
                        )}
                      </Button>
                    </>
                  )}
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: 10,
                      backgroundColor: color.white,
                      color: "#D8D8D8",
                    }}
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                  </Button>
                  <Popover
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    id={id}
                    open={pop}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: isTablet ? "center" : "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ListItemButton
                      selected={tabValue === 0}
                      onClick={(event) => {
                        setPageNoIH(1);
                        setRecordIH(10);
                        setTabValue(0);
                        setAnchorEl(null);
                        setSearchFilterIH("");
                        setDatePickerIH(null);
                      }}
                    >
                      Inspection History
                    </ListItemButton>
                    {/* <ListItemButton
                     selected={tabValue === 1}
                     onClick={() => {
                       setTabValue(1);
                       setAnchorEl(null);
                     }}
                   >
                     Issue Resolution History
                   </ListItemButton> */}
                    <ListItemButton
                      selected={tabValue === 1}
                      onClick={() => {
                        setPageNoMR(1);
                        setRecordMR(10);
                        setFilter("");
                        setTabValue(1);
                        setAnchorEl(null);
                        setSearchFilterMR("");
                        setDatePickerMR(null);
                      }}
                    >
                      Modification Requests
                    </ListItemButton>
                    <ListItemButton
                      selected={tabValue === 2}
                      onClick={() => {
                        setPageNoTR(1);
                        setRecordTR(10);
                        setFilterTR("");
                        setTabValue(2);
                        setAnchorEl(null);
                        setSearchFilterTR("");
                      }}
                    >
                      Tyre Removal Requests
                    </ListItemButton>
                    <ListItemButton
                      selected={tabValue === 3}
                      onClick={() => {
                        setTabValue(3);
                        setAnchorEl(null);
                      }}
                    >
                      Master Reports
                    </ListItemButton>
                  </Popover>
                </Grid>
              </Grid>
              {tabValue === 0 && (
                <TabInspectionHistory
                  ref={inspectionHistory}
                  pageNo={pageNoIH}
                  record={recordIH}
                  searchFilter={searchFilterIH}
                  datePicker={datePickerIH}
                  handlePageNo={(page) => {
                    setPageNoIH(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordIH(rec);
                  }}
                  handleSearch={(s) => {
                    setSearchFilterIH(s);
                  }}
                  handleDatePicker={(date) => {
                    setDatePickerIH(date);
                  }}
                  handleLoader={(v) => setReportLoader(v)}
                  handleClick={(type, data, scroll) => {
                    if (type === "inspectionHistory") {
                      setPageNoID(1);
                      setRecordID(10);
                      setFilterID("");
                      setChangeTab("inspectionHistory");
                      setInspactionData(data);
                    } else if (type === "inspectionIssue") {
                      setChangeTab("inspectionIssue");
                      setInspactionData(data);
                      setScroll(scroll);
                      setPageNoPT(1);
                      setPageNoPM(1);
                      setRecordPT(10);
                      setRecordPM(10);
                      setPTabValue(0);
                      setPageNoRT(1);
                      setRecordRT(10);
                      setPageNoRM(1);
                      setRecordRM(10);
                      setRTabValue(0);
                    } else if (type === "UnresolvedIssue") {
                      setPageNoUI(1);
                      setRecordUI(10);
                      setFilterUI("");
                      setChangeTab("UnresolvedIssue");
                      setInspactionData(data);
                    }
                  }}
                  from={dashboard ? "inspectionHistory" : ""}
                />
              )}
              {/* {tabValue === 1 && (
               <TabIssueResolution
                 ref={issueResolve}
                 handleClick={(type, data) => {
                   if (type === "inspectionIssue") {
                     setChangeTab("inspectionIssue");
                     setInspactionData(data);
                   } else if (type === "vehicleInspection") {
                     setChangeTab("vehicleInspection");
                     setInspactionData(data);
                     setFrom("issue");
                   }
                 }}
               />
             )} */}
              {tabValue === 1 && (
                <ModificationRequest
                  ref={modificationRequest}
                  fleetData={inspectionData}
                  pageNo={pageNoMR}
                  record={recordMR}
                  filter={filter}
                  searchFilter={searchFilterMR}
                  datePicker={datePickerMR}
                  handleLoader={(v) => setReportLoader(v)}
                  handlePageNo={(page) => {
                    setPageNoMR(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordMR(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilter(flt);
                  }}
                  handleSearch={(s) => {
                    setSearchFilterMR(s);
                  }}
                  handleDatePicker={(date) => {
                    setDatePickerMR(date);
                  }}
                  handleClick={(type, data) => {
                    if (type === "vehicleInspectionChanges") {
                      setChangeTab("vehicleInspectionChanges");
                      setInspactionData(data);
                    }
                  }}
                />
              )}
              {tabValue === 2 && (
                <TabRemovalTyreRequest
                  ref={removalTyreRequest}
                  filter={filterTR}
                  pageNo={pageNoTR}
                  record={recordTR}
                  searchFilter={searchFilterTR}
                  handlePageNo={(page) => {
                    setPageNoTR(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordTR(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilterTR(flt);
                  }}
                  handleSearch={(s) => {
                    setSearchFilterTR(s);
                  }}
                  handleLoader={(v) => setReportLoader(v)}
                />
              )}
              {tabValue === 3 && <MasterReport />}
            </div>
          ) : (
            <div className={styles.container}>
              <Grid container wrap="nowrap">
                <Grid
                  item
                  container
                  display="flex"
                  alignItems="center"
                  id="jump_to_me"
                  backgroundColor={color.secondary}
                  padding={"10px"}
                  color={color.white}
                  borderRadius={1}
                  wrap="nowrap"
                  justifyContent="space-between"
                  gap={1}
                >
                  <Grid item>
                    <Typography variant="tableTitle">
                      Inspection Management
                    </Typography>
                  </Grid>

                  {tabValue !== 3 && (
                    <Grid item marginLeft={"auto"}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setChangeTab("addInspection");
                        }}
                      >
                        Add Inspection
                      </Button>
                    </Grid>
                  )}
                  <Grid xs={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        backgroundColor: "#BBBBBB",
                      }}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVert style={{ color: color.black }} />
                    </Button>
                    <Popover
                      onClose={() => setAnchorEl(null)}
                      anchorEl={anchorEl}
                      id={id}
                      open={pop}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: isTablet ? "center" : "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ListItemButton
                        selected={tabValue === 0}
                        onClick={(event) => {
                          setPageNoIH(1);
                          setRecordIH(10);
                          setTabValue(0);
                          setAnchorEl(null);
                          setSearchFilterIH("");
                          setDatePickerIH(null);
                        }}
                      >
                        Inspection History
                      </ListItemButton>
                      {/* <ListItemButton
                      selected={tabValue === 1}
                      onClick={() => {
                        setTabValue(1);
                        setAnchorEl(null);
                      }}
                    >
                      Issue Resolution History
                    </ListItemButton> */}
                      <ListItemButton
                        selected={tabValue === 1}
                        onClick={() => {
                          setPageNoMR(1);
                          setRecordMR(10);
                          setTabValue(1);
                          setFilter("");
                          setAnchorEl(null);
                          setSearchFilterMR("");
                          setDatePickerMR(null);
                        }}
                      >
                        Modification Requests
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 2}
                        onClick={() => {
                          setPageNoTR(1);
                          setRecordTR(10);
                          setTabValue(2);
                          setFilterTR("");
                          setAnchorEl(null);
                          setSearchFilterTR("");
                        }}
                      >
                        Tyre Removal Requests
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 3}
                        onClick={() => {
                          setTabValue(3);
                          setAnchorEl(null);
                        }}
                      >
                        Master Reports
                      </ListItemButton>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
              <Grid marginTop={"10px"}>
                <Typography variant="tableTitle">
                  {tabValue === 1
                    ? "Modification Requests"
                    : tabValue === 2
                    ? "Tyre Removal Requests"
                    : tabValue === 3
                    ? "Master Reports"
                    : "Inspection History"}
                </Typography>
              </Grid>

              {tabValue === 0 && (
                <TabInspectionHistory
                  ref={inspectionHistory}
                  pageNo={pageNoIH}
                  record={recordIH}
                  searchFilter={searchFilterIH}
                  datePicker={datePickerIH}
                  handlePageNo={(page) => {
                    setPageNoIH(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordIH(rec);
                  }}
                  handleSearch={(s) => {
                    setSearchFilterIH(s);
                  }}
                  handleDatePicker={(date) => {
                    setDatePickerIH(date);
                  }}
                  handleClick={(type, data, scroll) => {
                    if (type === "inspectionHistory") {
                      setPageNoID(1);
                      setRecordID(10);
                      setFilterID("");
                      setChangeTab("inspectionHistory");
                      setInspactionData(data);
                    } else if (type === "inspectionIssue") {
                      setChangeTab("inspectionIssue");
                      setInspactionData(data);
                      setScroll(scroll);
                      setPageNoPT(1);
                      setPageNoPM(1);
                      setRecordPT(10);
                      setRecordPM(10);
                      setPTabValue(0);
                      setPageNoRT(1);
                      setRecordRT(10);
                      setPageNoRM(1);
                      setRecordRM(10);
                      setRTabValue(0);
                    } else if (type === "UnresolvedIssue") {
                      setPageNoUI(1);
                      setRecordUI(10);
                      setFilterUI("");
                      setChangeTab("UnresolvedIssue");
                      setInspactionData(data);
                    }
                  }}
                  from={dashboard ? "inspectionHistory" : ""}
                />
              )}
              {/* {tabValue === 1 && (
              <TabIssueResolution
                ref={issueResolve}
                handleClick={(type, data) => {
                  if (type === "inspectionIssue") {
                    setChangeTab("inspectionIssue");
                    setInspactionData(data);
                  } else if (type === "vehicleInspection") {
                    setChangeTab("vehicleInspection");
                    setInspactionData(data);
                    setFrom("issue");
                  }
                }}
              />
            )} */}
              {tabValue === 1 && (
                <ModificationRequest
                  ref={modificationRequest}
                  fleetData={inspectionData}
                  pageNo={pageNoMR}
                  record={recordMR}
                  filter={filter}
                  searchFilter={searchFilterMR}
                  datePicker={datePickerMR}
                  handleLoader={(v) => setReportLoader(v)}
                  handlePageNo={(page) => {
                    setPageNoMR(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordMR(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilter(flt);
                  }}
                  handleSearch={(s) => {
                    setSearchFilterMR(s);
                  }}
                  handleDatePicker={(date) => {
                    setDatePickerMR(date);
                  }}
                  handleClick={(type, data) => {
                    if (type === "vehicleInspectionChanges") {
                      setChangeTab("vehicleInspectionChanges");
                      setInspactionData(data);
                    }
                  }}
                />
              )}
              {tabValue === 2 && (
                <TabRemovalTyreRequest
                  ref={removalTyreRequest}
                  filter={filterTR}
                  pageNo={pageNoTR}
                  record={recordTR}
                  searchFilter={searchFilterTR}
                  handlePageNo={(page) => {
                    setPageNoTR(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordTR(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilterTR(flt);
                  }}
                  handleSearch={(s) => setSearchFilterTR(s)}
                  handleLoader={(v) => setReportLoader(v)}
                />
              )}
              {tabValue === 3 && <MasterReport />}
            </div>
          )}
        </>
      )}
    </div>
  );
}
